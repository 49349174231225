/*------------------------
    Contact Styles 
--------------------------*/

.rbt-address {
    padding: 40px;
    background: var(--color-white);
    padding-bottom: 50px;
    transition: 0.3s;
    border-radius: var(--radius);
    overflow: hidden;
    box-shadow: var(--shadow-1);
    height: 100%;

    @media #{$lg-layout} {
        padding: 30px;
        padding-bottom: 40px;
    }
    @media #{$md-layout} {
        padding: 30px;
        padding-bottom: 40px;
    }
    @media #{$sm-layout} {
        padding: 30px;
        padding-bottom: 40px;
    }

    .icon {
        color: var(--color-primary);
        border-radius: 100%;
        font-size: 33px;
        display: inline-block;
        margin-bottom: 12px;
        i {
            font-size: 40px;
        }
    }
    .inner {
        h4 {
            &.title {
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 1px;
            }
        }
        p {
            margin: 0;
            a {
                margin: 0;
                color: var(--color-body);
            }
        }
    }
}











