/*----------------------------
    Multi Step Form Styles  
------------------------------*/

.rbt-book-sec-01 {
    margin-top: -60px;
    position: relative;

    @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
        margin-top: 0;
        padding-top: 80px;
    }
}

.multi-step-form {
    padding: 80px;
    position: relative;
    z-index: 2;

    @media #{$md-layout, $sm-layout, $large-mobile} {
        padding: 30px;
    }

    @media #{$sm-layout, $large-mobile} {
        padding: 30px 0 0 0;
        box-shadow: none;
    }

    &::before {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 4px);
        border-radius: var(--radius-10);
        z-index: -1;
        background: linear-gradient(180deg, rgba(81, 99, 255, 0.612) 0%, rgba(197, 134, 238, 0.071) 50%, var(--color-white) 100%);

        @media #{$sm-layout, $large-mobile} {
            display: none;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-white);
        box-shadow: var(--shadow-11);
        backdrop-filter: blur(34px);
        border-radius: var(--radius-10);
        z-index: -1;
    }

    .shapes {
        .shape-1 {
            position: absolute;
            right: 0px;
            bottom: 50px;
            max-width: 185px;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .shape-2 {
            position: absolute;
            left: 145px;
            bottom: 0px;
            max-width: 215px;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }
    }
}

.multi-step-form-row {
    max-width: 870px;
    min-height: 400px;
    margin: 0 auto;
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-11);
    border-radius: var(--radius-10);
    display: grid;
    grid-template-columns: 240px 1fr;
    gap: 0;
    overflow: hidden;
    position: relative;
    z-index: 2;

    @media #{$sm-layout, $large-mobile} {
        grid-template-columns: 1fr;
    }
}

.multi-step-form-left {
    background: linear-gradient(167deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contact {
        border-top: 1px solid var(--color-border-2);
        padding-top: 15px;

        .title {
            font-weight: var(--f-medium);
            font-size: 14px;
            line-height: 20px;
            color: var(--color-white);
            margin-bottom: 10px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-top: 0;
                line-height: 1.35;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                i {
                    color: var(--color-white);
                    font-size: 14px;
                    margin-right: 6px;
                }

                a {
                    color: var(--color-white);
                    font-size: 14px;
                    transition: var(--transition);

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

.multisteps-form__progress {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
   
.multisteps-form__progress-btn {
    transition-property: all;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    transition-delay: 0s;
    position: relative;
    padding: 10px;
    border: none;
    outline: none !important;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0)
    }

    .title {
        font-weight: var(--f-medium);
        font-size: 14px;
        line-height: 20px;
        color: var(--color-white);
        margin-bottom: 0;

        .icon {
            margin-right: 4px;
            transform: translateY(2px);
            display: inline-block;
            font-size: 16px;
        }
    }

    .check-icon {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary-opacity);
        font-size: 10px;

        i {
            opacity: 0;
        }
    }
}
.multisteps-form__progress-btn {
    &.rbt-active {
        .check-icon {
            background: var(--color-white);
    
            i {
                opacity: 1;
            }
        }
    }
}
   
.multisteps-form__form {
    position: relative;
    height: 100%;
}
   
.multisteps-form__panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .rbt-btn {
        height: 50px;
        line-height: 50px;
    }

    .btn-wrap {
        padding: 15px 30px;
        border-top: 1px solid var(--color-border);
    }

    .select-label {
        font-size: 14px;
        opacity: 1;
    }

    &.rbt-active {
        height: 100%;
        opacity: 1;
        visibility: visible;
    }
}
  
.multi-step-form-right {
    @media #{$sm-layout, $large-mobile} {
        min-height: 400px;
    }
}