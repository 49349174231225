/*---------------------
    Header Top  
----------------------*/

.rbt-header-top {
    .container-fluid {
        max-width: 1920px;
    }
    
    &.rbt-header-top-1 {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        min-height: 40px;
        padding-top: 7px;
        padding-bottom: 7px;

        @media #{$sm-layout} {
            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 85%;
                display: block;
                z-index: 1;
                content: "";
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--dark-border) 100%);
                transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
                cursor: pointer;
                opacity: 1;
            }
        }


        &.variation-height-60 {
            @media #{$lgtolp-device} {
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 60px;
            }
        }

        &.variation-height-50 {
            padding-top: 4px;
            padding-bottom: 4px;
            height: 50px;
            min-height: 50px;

            @media #{$laptop-device} {
                height: auto;
                min-height: auto;
            }

            @media #{$lg-layout} {
                height: auto;
            }

            @media #{$md-layout} {
                height: 40px;
                min-height: auto;
                overflow: hidden;
            }

            @media #{$sm-layout} {
                height: 40px;
                min-height: auto;
                overflow: hidden;
            }
        }

        .rbt-header-sec {
            flex-wrap: wrap;

            .rbt-header-sec-col {
                @media #{$laptop-device} {
                    // flex-basis: 33.33%;
                    flex-basis: auto;
                }

                @media #{$lg-layout} {
                    // flex-basis: 50%;
                    flex-basis: auto;
                }

                @media #{$md-layout} {
                    flex-basis: 100%;
                }

                @media #{$sm-layout} {
                    flex-basis: 100%;
                }
            }
        }

        .top-expended-wrapper {
            height: 32px;
            transition: 0.3s linear;
        }

        &.top-expended-activation {
            transition: height .3s linear;

            @media #{$md-layout} {
                overflow: hidden;
            }

            @media #{$sm-layout} {
                overflow: hidden;
            }

            &.active {
                overflow: visible !important;

                @media #{$sm-layout} {
                    &::after {
                        display: none;
                    }
                }

                .topbar-expend-button {
                    i {
                        &::before {
                            content: "\e996";
                        }
                    }
                }
            }
        }
    }

    .top-bar-expended {
        position: absolute;
        right: 25px;
        top: 10px;
        z-index: 99;

        .rbt-round-btn {
            width: auto;
            height: auto;
            line-height: inherit;
            transform: rotate(0deg);

            &::after {
                display: none;
            }

            i {
                color: var(--color-white);
            }
        }
    }
}

/*----------------------------
    Header Top Bar  
----------------------------*/
.rbt-header-top-2 {
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-white);

    p {
        margin-bottom: 0;
        font-size: 14px;

        a {
            transition: 0.3s;

            i {
                position: relative;
                top: 2px;
            }
        }
    }

    .address-content {
        display: flex;
        align-items: center;
        margin: 0 -10px;

        p {
            margin-bottom: 0;
            padding: 0 15px;
            font-size: 14px;
            position: relative;
            display: flex;
            align-items: center;

            @media #{$large-mobile} {
                padding: 0 8px;
            }

            &+p {
                &::before {
                    position: absolute;
                    content: "";
                    height: 20px;
                    width: 2px;
                    background: var(--color-border);
                    top: 50%;
                    transform: translateY(-50%);
                    left: -1px;
                    opacity: 0.5;
                }
            }

            a {
                transition: 0.3s;

                &:hover {
                    color: var(--color-primary);
                }
            }

            i {
                margin-right: 8px;
                font-size: 14px;
            }
        }
    }

    .fancy-menu-text {
        @media #{$md-layout} {
            text-align: center;
        }

        @media #{$sm-layout} {
            text-align: center;
        }
    }

    .fancy-menu-address {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media #{$md-layout} {
            justify-content: center;
            margin-top: 6px;
        }

        @media #{$sm-layout} {
            justify-content: center;
            margin-top: 6px;
        }
    }


    .fancu-menu-end {
        justify-content: flex-end;
        text-align: end;
    }


    &.header-top-2 {
        .header-right {
            @media #{$md-layout} {
                justify-content: end;
            }

            @media #{$sm-layout} {
                justify-content: center;
            }

            @media #{$small-mobile} {
                display: block;
            }

            .social-icon-wrapper {
                margin-left: 10px;
                padding-left: 10px;
                flex-basis: auto;

                @media #{$small-mobile} {
                    margin-top: 10px;
                }

                .social-default {
                    &.icon-naked {
                        margin: -5px;
                    }
                }

            }
        }
    }

    .social-default {
        &.icon-naked {
            li {
                margin: 5px;

                a {
                    color: var(--color-body);
                    font-size: 15px;
                    line-height: 14px;
                    display: block;
                }
            }
        }
    }

    .social-icon-wrapper {
        position: relative;
        margin-left: 10px;
        padding-left: 15px;

        @media #{$large-mobile} {
            margin-left: 0;
            padding-left: 0;
            flex-basis: 100%;
        }

        &::before {
            position: absolute;
            content: "";
            height: 20px;
            width: 2px;
            background: var(--color-border);
            top: 50%;
            transform: translateY(-50%);
            left: -1px;
            opacity: 0.5;

            @media #{$large-mobile} {
                display: none;
            }
        }
    }

    &.header-top-transparent {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        background-color: transparent;
    }

    &.color-white {
        p {
            color: var(--color-white);
            opacity: 0.7;
        }

        a {
            color: var(--color-white);
        }

        .social-default {
            li {
                opacity: 0.7;

                a {
                    color: var(--color-white);
                }
            }
        }
    }
}


/*------------------------------------------
    Header With TopBar Transparent  
----------------------------------------------*/

.rbt-transparent-header {
    .rbt-header-top {
        border-color: #525260;
    }
}