/*----------------------------
    BG Color Darker  
------------------------------*/
.bg-color-darker,
.bg-color-dark {

    --dark-border: #525260;
    --dark-body: #acacac;


    color: var(--color-white);


    input[type=text], 
    input[type=password], 
    input[type=email], 
    input[type=number], 
    input[type=tel], 
    textarea,
    select {
        color: var(--color-white-off);
    }

    // Information List 
    .rbt-information-list {
        li {
            a {
                color: var(--color-white-off);
                &:hover {
                    color: var(--color-white);
                }
            }
        }
    }

    .social-share-transparent {
        li {
            a {
                color: var(--color-white-off);
                &:hover {
                    color: var(--color-white);
                }
            }
        }
    }
    .quick-access > li > a {
        color: var(--color-white-off);
    }
    .rbt-header-top-news .inner .content .news-text,
    ul.rbt-dropdown-menu > li > a,
    .rbt-search-with-category .search-field .serach-btn,
    .rbt-secondary-menu li a {
        color: var(--color-white-off);
    }

    .rbt-btn.btn-border-gradient {
        box-shadow: none;
    }

    .rbt-search-with-category {
        border-color: var(--dark-border);
    }


    .rbt-search-with-category .search-by-category::after,
    .rbt-separator::after {
        background: var(--dark-border);
    }

    .rbt-round-btn::after,
    .social-share-transparent li a::before {
        background: var(--white-opacity);
    }



    h1, .h1, h2, .h2, h3, .h3,
    &.footer-style-1 .ft-title {
        color: var(--color-white) !important;
    }

    p,
    .footer-widget .ft-link li span,
    .footer-widget .ft-link li a,
    .rbt-author-meta .rbt-author-info a,
    .rbt-author-meta .rbt-author-info,
    .rbt-link-hover a,
    .copyright-style-1 .copyright-link li a,
    .form-group label {
        color: var(--color-white-off);
    }


    hr {
        background-color: var(--dark-border);
    }

    .form-group input, .form-group textarea,
    .copyright-style-1 .copyright-link li + li::after {
        border-color: var(--dark-border);
    }


    .social-default li a {
        background-color: var(--color-darkest);
        color: var(--color-white);
    }
    .rbt-btn.btn-border-gradient {
        color: var(--color-white);
    }
    .rbt-btn.btn-border-gradient::before {
        background: var(--color-darkest);
    }







}
