/*-----------------------
    Social Icons 
-------------------------*/
.social-default {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: center;
    margin: -5px;
    li {
        margin: 5px;
        a {
            width: 48px;
            background-color: var(--color-gray-light);
            height: 48px;
            line-height: 45px;
            text-align: center;
            display: inline-block;
            border-radius: 100%;
            transition: 0.3s;
            color: var(--color-body);
            z-index: 2;
            position: relative;
            &::after {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                background: var(--color-primary);
                transform: scale(0.5);
                opacity: 0;
                transition: 0.3s;
                border-radius: 100%;
            }
            &:hover {
                color: var(--color-white);
                &::after {
                    transform: scale(1);
                    opacity: 1;
                }
            }
            i {
                vertical-align: middle;
            }
        }
    }

    &.transparent-with-border {
        li {
            a {
                line-height: 41px;
                background: transparent;
                border: 2px solid var(--color-border);
                &:hover {
                    border-color: transparent !important;
                }
                &::after {
                    border: 1px solid var(--color-primary);
                }
            }
        }
    }

    &.with-gradient {
        li {
            a {
                &::after {
                    background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-secondary), var(--color-primary));
                    background-size: 300% 100%;
                }
            }
        }
    }

    &.with-bg-primary {
        li {
            a {
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                transition: 0.5s;
                line-height: 40px;
                color: var(--color-white);
                &::after {
                    display: none;
                }
                &:hover {
                    background-color: transparent;
                    color: var(--color-primary);
                }
            }
        }
    }

    &.icon-naked {
        margin: -10px;
        li {
            margin: 10px;
            a {
                background: transparent !important;
                width: auto;
                height: auto;
                line-height: initial;
                &::after {
                    display: none;
                }
                &:hover {
                    color: var(--color-primary) !important;
                }
            }
        }
    }

    &.icon-large {
        li {
            a {
                font-size: 36px;
                opacity: 0.7;
            }
        }
    }

    &.color-white {
        li {
            a {
                color: var(--color-white);
            }
        }
    }


}