/*--------------------------
    Switcher Styles  
---------------------------*/

.my_switcher {
    position: fixed;
    left: 30px;
    transform: rotate(90deg);
    z-index: 99;
    bottom: 30px;

    @media #{$md-layout} {
        left: 10px;
    }

    @media #{$sm-layout} {
        left: 4px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        border: 2px solid var(--color-border);

        border-radius: 100px;
        line-height: 40px;

        li {
            margin: 0;
            padding: 0;

            a,
            button {
                position: relative;
                font-size: 12px;
                line-height: 22px;
                font-weight: 500;
                text-decoration: none;
                transition: .4s;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                letter-spacing: .3px;
                width: 40px;
                height: 40px;

                img {
                    width: 15px;
                }

                span {
                    transform: rotate(-180deg);
                    display: none;
                }

                &.active {
                    display: none;
                }

                &.light {
                    color: #ffffff;
                    background: transparent;
                    outline: 0;
                    border: 0;
                }

                &.dark {
                    color: #18191A;
                    background: transparent;
                    outline: 0;
                    border: 0;
                }
            }
        }
    }
}