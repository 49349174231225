/*---------------------------------------
    11. Cart
-----------------------------------------*/

.cart-submit-btn-group {
    margin: -10px;
    display: flex;
    flex-wrap: wrap;

    .single-button {
        padding: 10px;
    }
}

.rbt-cart-area {
    .section-title {
        .title {
            border-bottom: 1px dashed var(--color-border);
            padding-bottom: 10px;
        }
    }
}


.cart-table {
    & .table {
        border-bottom: 8px solid var(--color-border);
        margin: 0;

        @media #{$large-mobile} {
            border-top: 8px solid transparent;
        }

        & thead {
            background-color: var(--color-primary);
            background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-secondary), var(--color-primary));
            background-size: 300% 100%;

            @media #{$large-mobile} {
                display: none;
            }

            & tr {
                & th {
                    text-align: center;
                    border: none;
                    font-size: 18px;
                    text-transform: inherit;
                    font-weight: 500;
                    color: var(--color-white);
                    background-color: transparent;
                    padding: 12px 20px;

                    &:first-child {
                        border-radius: 6px 0 0 6px;
                    }

                    &:last-child {
                        border-radius: 0 6px 6px 0;
                    }
                }
            }
        }

        & tbody {
            & tr {
                transition: 0.4s;

                &:hover {
                    box-shadow: var(--shadow-1);
                }

                & td {
                    text-align: center;
                    border: none;
                    padding: 25px 20px;
                    vertical-align: middle;
                    border-bottom: 1px solid var(--color-border);
                    padding-left: 0;

                    @media #{$large-mobile} {
                        display: block;
                        width: 100%;
                        max-width: none;
                        padding: 15px;
                        text-align: left;
                    }
                }
            }
        }
    }
}



.cart-table .table>:not(:first-child) {
    border-top: 0 none;
}



.cart-table {

    & th.pro-thumbnail,
    td.pro-thumbnail {
        max-width: 160px;
        min-width: 120px;
        width: 160px;

        @media #{$large-mobile} {
            & a {
                width: 160px;
            }
        }
    }

    & th.pro-title,
    td.pro-title {
        min-width: 200px;
    }

    & td.pro-thumbnail {
        & a {
            display: block;

            & img {
                width: 100%;
                background-color: #f6f7f8;
                border-radius: 6px;
            }
        }
    }

    & td.pro-title {
        & a {
            font-size: 16px;
            font-weight: 600;
            color: var(--color-heading);
            transition: 0.4s;

            &:hover {
                color: var(--color-primary);
            }
        }
    }

    & td.pro-price {
        & span {
            display: block;
            font-size: 15px;
            font-weight: 600;
            color: var(--color-body);
        }
    }

    & td.pro-quantity {
        & .pro-qty {
            max-width: 120px;
            height: 50px;
            border: 1px solid var(--color-border);
            border-radius: 6px;
            padding: 0;
            display: flex;
            margin: auto;
            min-width: 50px;
            align-items: center;

            & .qtybtn {
                width: 45px;
                display: block;
                float: left;
                line-height: 50px;
                cursor: pointer;
                text-align: center;
                font-size: 20px;
                font-weight: 700;
                color: var(--color-body);
                height: 50px;
            }

            & input {
                width: 28px;
                float: left;
                border: none;
                height: 33px;
                line-height: 33px;
                padding: 0;
                text-align: center;
                background-color: transparent;
                box-shadow: none;
            }

            @media #{$large-mobile} {
                margin: 0;
            }
        }
    }

    & td.pro-subtotal {
        & span {
            display: block;
            font-size: 15px;
            font-weight: 600;
            color: var(--color-primary);
        }
    }

    & td.pro-addtocart {
        & button {
            width: 140px;
            border-radius: 50px;
            height: 36px;
            line-height: 24px;
            padding: 5px 20px;
            text-transform: capitalize;
        }
    }

    .pro-remove {
        a {
            display: block;
            font-weight: 600;
            position: relative;
            width: 50px;
            height: 50px;
            margin: 0 auto;
            line-height: 56px;
            z-index: 1;

            &::after {
                background: var(--color-gray-light);
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                transition: 0.4s;
                opacity: 0;
                transform: scale(0.8);
                border-radius: 100%;
                z-index: -1;
            }

            &:hover {
                &::after {
                    opacity: 1;
                    transform: scale(1);
                }
            }

            i {
                font-size: 24px;
            }

            @media #{$large-mobile} {
                width: 60px;
                text-align: center;
            }
        }
    }
}

/*-- Calculate Shipping --*/

.calculate-shipping {
    margin-bottom: 23px;

    & form {
        & .nice-select {
            width: 100%;
            border-radius: 6px;
            height: 45px;
            border: 1px solid var(--color-border);
            line-height: 24px;
            padding: 5px 20px;
            background-color: transparent;

            &::after {
                border-color: var(--color-body);
            }

            & .current {
                display: block;
                line-height: 24px;
                font-size: 14px;
                color: var(--color-body);
            }
        }

        & input {
            width: 100%;
            border-radius: 6px;
            height: 45px;
            border: 1px solid var(--color-border);
            line-height: 24px;
            padding: 5px 20px;
            color: var(--color-body);
            background-color: transparent;

            &[type="submit"] {
                font-weight: 700;
                text-transform: uppercase;
                color: #fff;
                background-color: var(--color-primary);
                border-color: var(--color-primary);
                width: 140px;
            }
        }
    }
}

/*-- Discount Coupon --*/

.discount-coupon {

    & form {
        & input {
            width: 100%;
            border-radius: 6px;
            height: 45px;
            border: 1px solid var(--color-border);
            line-height: 24px;
            padding: 5px 20px;
            color: var(--color-body);
            background-color: transparent;

            &[type="submit"] {
                font-weight: 700;
                text-transform: uppercase;
                color: #fff;
                background-color: var(--color-primary);
                border-color: var(--color-primary);
                width: 140px;
            }
        }
    }
}

/*-- Cart Summary --*/

.cart-summary {
    .cart-summary-wrap {
        background-color: var(--color-white);
        padding: 45px 50px;
        margin-bottom: 30px;
        box-shadow: var(--shadow-1);
        border-radius: 6px;

        @media #{$small-mobile} {
            padding: 25px 30px;
        }

        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 23px;
            color: var(--color-body);
            margin: 0;
            padding: 12px 0;

            span {
                float: right;
            }

            &+p {
                border-top: 1px dashed var(--color-border);
            }
        }

        h2 {
            border-top: 2px solid var(--color-border);
            padding-top: 14px;
            font-size: 18px;
            line-height: 23px;
            font-weight: 700;
            color: var(--color-heading);
            margin: 0;
            margin-top: 20px;

            span {
                float: right;
            }
        }

    }

    .cart-summary-button {
        overflow: hidden;
        width: 100%;

        @media #{$sm-layout} {
            display: flex;
            justify-content: flex-start;
        }

        @media #{$large-mobile} {
            display: flex;
            justify-content: flex-start;
        }

        button {
            margin-top: 10px;
            width: 140px;
            border-radius: 50px;
            height: 36px;
            border: 1px solid var(--color-border);
            line-height: 24px;
            padding: 5px 20px;
            color: var(--color-body);
            background-color: transparent;
            margin-left: 20px;
            float: right;

            &:last-child {
                margin-left: 0;
            }

            &.checkout-btn {
                font-weight: 700;
                text-transform: uppercase;
                color: #fff;
                background-color: var(--color-primary);
                border-color: var(--color-primary);
            }

            @media #{$sm-layout} {
                margin-left: 0;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }

            @media #{$large-mobile} {
                margin-left: 0;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }

            @media #{$small-mobile} {
                width: 130px;
            }
        }
    }
}



.pro-quantity {
    & .pro-qty {
        max-width: 120px;
        height: 50px;
        border: 1px solid var(--color-border);
        border-radius: 6px;
        padding: 0;
        display: flex;
        margin: auto;
        min-width: 50px;
        align-items: center;

        & .qtybtn {
            width: 45px;
            display: block;
            float: left;
            line-height: 50px;
            cursor: pointer;
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            color: var(--color-body);
            height: 50px;
        }

        & input {
            width: 28px;
            float: left;
            border: none;
            height: 33px;
            line-height: 33px;
            padding: 0;
            text-align: center;
            background-color: transparent;
            box-shadow: none;
            margin-bottom: 0;
        }

        @media #{$large-mobile} {
            margin: 0;
        }
    }
}