/*------------------------------
 * HiStudy Banner Styles
---------------------------------*/
.active-header-transparent {
    .rbt-banner-area {
        padding-top: 100px;
    }
}

.height-350 {
    height: 350px;
}

.height-245 {
    height: 245px;
}


.height-850 {
    height: 850px;
    display: flex;
    align-items: center;

    @media #{$smlg-device} {
        height: auto;
    }
}

.height-650 {
    height: 650px;
    display: flex;
    align-items: center;

    @media #{$smlg-device} {
        height: auto;
    }
}

.height-750 {
    height: 750px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
    }

    @media #{$sm-layout} {
        height: auto;
    }
}

.height-950 {
    height: 950px;
    display: flex;
    align-items: center;

    @media #{$md-layout} {
        height: auto;
    }

    @media #{$sm-layout} {
        height: auto;
    }
}

.rbt-display-1 {
    font-size: 80px !important;
    line-height: 1.1 !important;

    @media #{$lg-layout} {
        font-size: 70px !important;
    }

    @media #{$md-layout} {
        font-size: 60px !important;
    }

    @media #{$sm-layout} {
        font-size: 40px !important;
    }

    @media #{$large-mobile} {
        font-size: 28px !important;
    }
}

.rbt-display-2 {
    font-size: 53px !important;
    line-height: 1.3 !important;

    @media #{$sm-layout} {
        font-size: 42px;
    }
}


/*-------------------------------------------------------
    Banner Style Home One And Home Five (variation-2)
-------------------------------------------------------------*/

.rbt-banner-1 {
    background-color: var(--color-light);
    padding: 0 150px;
    position: relative;
    overflow: hidden;
    background-image: url(../images/bg/bg-g1.webp);
    background-position: top center;
    background-size: cover;

    @media #{$laptop-device} {
        padding: 0 50px;
    }

    @media #{$lg-layout} {
        padding: 0 30px;
    }

    @media #{$md-layout} {
        padding: 0 60px;
    }

    @media #{$sm-layout} {
        padding: 0 30px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        background: url(../images/bg/banner-bg-shape-1.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100vw;
        height: 145px;
        z-index: 9;

        @media #{$lg-layout} {
            height: 142px;
        }

        @media #{$sm-layout} {
            height: 109px;
        }

        @media (min-width: 1921px) {
            height: 250px;
        }
    }

    .container-fluid {
        max-width: 1920px;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;


        @media #{$lg-layout} {
            display: block;
        }

        @media #{$md-layout} {
            display: block;
        }

        @media #{$sm-layout} {
            display: block;
        }

        .inner {
            position: relative;
            z-index: 1;

            .title {
                font-size: 64px;
                font-weight: 700;
                margin-top: 40px;
                margin-bottom: 20px;

                @media #{$extra-device} {
                    font-size: 50px;
                }

                @media #{$laptop-device} {
                    font-size: 40px;
                }

                @media #{$lg-layout} {
                    font-size: 54px;
                }

                @media #{$md-layout} {
                    font-size: 42px;
                }

                @media #{$sm-layout} {
                    font-size: 42px;
                    margin-top: 20px;
                }

                @media #{$large-mobile} {
                    font-size: 32px;
                }
            }

            .description {
                color: var(--color-body);
                font-size: 22px;
                margin-bottom: 40px;
                font-weight: 400;
                padding-right: 24px;

                @media #{$extra-device} {
                    font-size: 20px;
                    margin-bottom: 30px;
                }

                @media #{$laptop-device} {
                    padding-right: 18%;
                    font-size: 18px;
                }

                @media #{$lg-layout} {
                    padding-right: 26%;
                }

                @media #{$large-mobile} {
                    font-size: 16px;
                    margin-bottom: 20px;
                    padding-right: 0;
                }
            }
        }

        .shape-wrapper {
            margin-top: 20px;
            z-index: 2;
            margin-bottom: 6px;
            position: relative;
            left: -40px;

            @media screen and (max-width: 1400px) {
                left: -105px;
                max-width: 320px;
            }

            @media #{$lg-layout} {
                left: 0;
            }

            @media #{$md-layout} {
                left: -76px;
                bottom: -14px;
            }

            @media #{$sm-layout} {
                display: none;
            }

            img {
                max-width: none;
                height: auto;
                max-width: 534px;

                @media #{$md-layout} {
                    max-width: 450px;
                }

                @media #{$sm-layout} {
                    max-width: 450px;
                }
            }

            .hero-bg-shape-1 {
                position: absolute;
                top: 112px !important;
                left: 5px !important;
                z-index: -3;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            .hero-bg-shape-2 {
                position: absolute;
                top: 112px !important;
                left: 70px !important;
                z-index: -2;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        .banner-card {
            position: relative;
            max-width: 390px;
            overflow: visible;


            @media #{$lg-layout} {
                position: absolute;
                max-width: 350px;
                bottom: 12%;
                right: 7%;
                z-index: 2;
            }

            @media #{$md-layout} {
                position: absolute;
                max-width: 290px;
                bottom: 12%;
                right: 40px;
                z-index: 2;
            }

            @media #{$sm-layout} {
                position: relative;
                max-width: 320px;
                bottom: 12%;
                right: 40px;
                z-index: 2;
                margin-top: 40px;
            }

            @media #{$large-mobile} {
                right: 0;
                left: 0;
            }
        }
    }

    &.variation-2 {
        @media #{$md-layout} {
            padding: 0 60px;
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @media #{$sm-layout} {
            padding: 0 15px;
            padding-top: 60px;
            padding-bottom: 50px;
        }

        &::after {
            display: none;
        }

        .content {
            display: block;

            @media #{$lg-layout} {
                padding-right: 10%;
            }

            .banner-card {
                margin: 0;

                @media #{$md-layout} {
                    position: static;
                    margin-top: 40px;
                }

                @media #{$sm-layout} {
                    position: static;
                    margin-top: 40px;
                }
            }

            .inner {
                .title {
                    font-size: 60px;
                    font-weight: 700;
                    margin-top: 40px;
                    margin-bottom: 29px;
                    padding-right: 10%;

                    @media #{$laptop-device} {
                        font-size: 50px;
                    }

                    @media #{$lg-layout} {
                        font-size: 40px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }

                    @media #{$md-layout} {
                        font-size: 40px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }

                    @media #{$sm-layout} {
                        font-size: 30px;
                        padding-right: 0;
                        margin-bottom: 15px;
                    }
                }

                .description {
                    padding-right: 17%;

                    @media #{$laptop-device} {
                        padding-right: 20%;
                    }

                    @media #{$lg-layout} {
                        font-size: 20px;
                    }

                    @media #{$md-layout} {
                        font-size: 18px;
                    }

                    @media #{$sm-layout} {
                        font-size: 18px;
                    }
                }

                .rbt-new-badge {
                    &.rbt-new-badge-one {
                        &::before {
                            box-shadow: none;
                        }
                    }
                }

            }
        }
    }

}


/*------------------------------
    Banner Style Home Two  
-------------------------------*/

.rbt-banner-2 {
    background-color: var(--color-light);
    position: relative;
    background-image: url(../images/bg/bg-g1.webp);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    .banner-content {
        .inner {
            position: relative;
            z-index: 1;

            .title {
                font-size: 90px;
                font-weight: 600;
                letter-spacing: -2.5px;
                max-width: 950px;
                line-height: 1.06;
                margin: 0 auto;
                margin-bottom: 45px;

                @media #{$lg-layout} {
                    font-size: 60px;
                }

                @media #{$md-layout} {
                    font-size: 50px;
                }

                @media #{$sm-layout} {
                    font-size: 50px;
                }

                @media #{$large-mobile} {
                    font-size: 35px;
                    line-height: 1.3;
                }
            }
        }
    }

    .service-wrapper {
        position: relative;

        .rbt-service {
            &.rbt-service-2 {
                position: relative;
                top: 145px;
            }
        }
    }

    .wrapper {
        padding-top: 50px;
        padding-bottom: 100px;
    }
}


/*-----------------------
    Home 03 Banner 
------------------------*/

.rbt-banner-3 {
    overflow: hidden;
    z-index: 1;
    position: relative;

    .wrapper {
        padding-top: 90px;
        padding-bottom: 110px;

        @media #{$md-layout} {
            padding-top: 50px;
            padding-bottom: 80px;
        }

        @media #{$sm-layout} {
            padding-top: 50px;
            padding-bottom: 80px;
        }
    }

    .banner-content {
        .inner {
            .section-title {
                margin-bottom: 10px;
            }

            .title {
                font-size: 75px;
                font-weight: 700;
                margin-bottom: 30px;

                @media #{$lg-layout} {
                    font-size: 55px;
                }

                @media #{$md-layout} {
                    font-size: 45px;
                    margin-bottom: 18px;
                }

                @media #{$sm-layout} {
                    font-size: 42px;
                    margin-bottom: 18px;
                }

                @media #{$large-mobile} {
                    font-size: 36px;
                }
            }

            .description {
                font-size: 20px;
                line-height: 1.8;
                margin-bottom: 26px;

                @media #{$sm-layout} {
                    font-size: 18px;
                    margin-bottom: 23px;
                }
            }

            .profile-share {
                @media #{$sm-layout} {
                    display: block;
                }

                a {
                    img {
                        max-width: 60px;
                    }
                }

                .more-author-text {
                    @media #{$sm-layout} {
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .top-shape {
        position: absolute;
        z-index: -1;
        top: -5px;
        left: 70px;

        @media #{$laptop-device} {
            left: -100px;
        }
    }

    .left-shape {
        position: absolute;
        z-index: -1;
        bottom: 0;
        right: 0;

        @media #{$laptop-device} {
            bottom: -120px;
        }

        @media #{$lg-layout} {
            width: 75%;
        }
    }

    .rbt-contact-form {
        &.contact-form-style-1 {
            margin-left: auto;

            @media #{$md-layout} {
                margin-left: 0;
            }

            @media #{$sm-layout} {
                margin-left: 0;
            }
        }
    }

    .edumarque {
        position: absolute;
        z-index: -1;
        bottom: 0;
        right: 0;
        background-image: url(../images/banner/histudy-text.png);
        background-repeat: repeat-x;
        background-size: auto;
        height: 206px;
        width: 52%;

        @media #{$lg-layout} {
            height: 275px;
        }

        @media #{$sm-layout} {
            width: 72%;
        }
    }
}

/*-----------------------
    Home 04 Banner  
------------------------*/
.rbt-banner-4 {
    position: relative;

    .wrapper {
        padding: 140px 0;

        @media #{$md-layout} {
            padding: 80px 0;
        }

        @media #{$sm-layout} {
            padding: 60px 0;
        }
    }

    .inner {
        position: relative;
        z-index: 1;
    }

    .content {
        .description {
            font-weight: 500;
            letter-spacing: -0.5px;
            margin-top: 35px;
            font-size: 24px;
            margin-bottom: 40px;

            @media #{$md-layout} {
                font-weight: 400;
                margin-top: 20px;
                font-size: 20px;
                margin-bottom: 20px;
            }

            @media #{$sm-layout} {
                font-weight: 400;
                margin-top: 20px;
                font-size: 18px;
                margin-bottom: 20px;
            }
        }

        .rbt-list-style-2 {
            @media #{$md-layout} {
                margin-bottom: 20px;
            }

            @media #{$sm-layout} {
                margin-bottom: 20px;
            }
        }
    }

    .banner-images {
        .shape-image {
            position: absolute;
            bottom: 50px;
            max-height: 560px;
            object-fit: cover;

            &.shape-image-1 {
                left: 10%;
            }

            &.shape-image-2 {
                right: 10%;
            }
        }
    }

    .shape {
        position: absolute;
        z-index: -1;

        &.shape-1 {
            top: 20%;
            left: 200px;

            img {
                max-height: 100px;
            }
        }

        &.shape-2 {
            top: 20%;
            right: 160px;

            img {
                max-height: 100px;
            }
        }

        &.shape-3 {
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);

            img {
                max-height: 240px;
            }
        }

        &.shape-4 {
            bottom: 20px;
            right: 4%;

            img {
                max-height: 150px;
            }
        }
    }
}

/*--------------------------------
    Banner Home 06 && 12   
--------------------------------*/

.rbt-banner-5 {
    @media #{$lg-layout} {
        padding: 100px 0;
    }

    @media #{$md-layout} {
        padding: 100px 0;
    }

    @media #{$sm-layout} {
        padding: 100px 0;
    }

    .inner {
        .subtitle {
            font-size: 18px;
            letter-spacing: 0.5px;
            display: block;
            margin-bottom: 18px;
            color: var(--color-white);

            @media #{$md-layout} {
                font-size: 16px;
                margin-bottom: 10px;
            }

            @media #{$sm-layout} {
                font-size: 16px;
                margin-bottom: 10px;
            }

            @media #{$large-mobile} {
                font-size: 14px;
            }
        }

        .title {
            margin-bottom: 25px;
            display: inline-block;
            color: var(--color-white);
            font-size: 70px;
            padding: 0 25%;

            @media #{$laptop-device} {
                max-width: 100%;
            }

            @media #{$lg-layout} {
                max-width: 100%;
                font-size: 45px;
            }

            @media #{$md-layout} {
                font-size: 40px;
                padding: 0 17%;
            }

            @media #{$sm-layout} {
                font-size: 42px;
                padding: 0;
            }

            @media #{$large-mobile} {
                font-size: 32px;
            }

            span {
                display: inline-block;

                label {
                    margin-bottom: 0;
                }
            }
        }

        .description {
            font-family: var(--font-primary);
            padding: 0 22%;
            font-size: 24px;
            line-height: 1.7;
            margin-bottom: 40px;
            color: var(--color-white);
            opacity: 0.7;
            letter-spacing: -0.5px;

            @media #{$lg-layout} {
                padding: 0 14%;
                font-size: 22px;
                margin-bottom: 35px;
            }

            @media #{$md-layout} {
                padding: 0 1%;
                font-size: 20px;
                margin-bottom: 30px;
            }

            @media #{$sm-layout} {
                padding: 0 1%;
                font-size: 20px;
                margin-bottom: 30px;
            }

            @media #{$large-mobile} {
                font-size: 18px;
                margin-bottom: 25px;
            }
        }

        .list-icon {
            li {
                color: var(--color-body);

                .icon {
                    background-color: var(--color-blackest) !important;
                }
            }
        }

        .header-caption {
            span {
                color: var(--color-white);
                font-size: 85px;

                @media #{$lg-layout} {
                    font-size: 70px;
                }

                @media #{$md-layout} {
                    font-size: 65px;
                }

                @media #{$sm-layout} {
                    font-size: 65px;
                }

                @media #{$large-mobile} {
                    font-size: 46px;
                }
            }
        }

        &.text-start {
            .title {
                padding: 0;
                max-width: 36%;
                font-size: 53px;
                line-height: 1.3;

                @media #{$laptop-device} {
                    max-width: 50%;
                }

                @media #{$lg-layout} {
                    max-width: 71%;
                }

                @media #{$md-layout} {
                    max-width: 69%;
                    padding: 0;
                }

                @media #{$sm-layout} {
                    max-width: 100%;
                    font-size: 34px;
                }
            }

            .description {
                padding: 0;
                max-width: 33%;

                @media #{$lg-layout} {
                    padding: 0;
                    max-width: 48%;
                }

                @media #{$md-layout} {
                    padding: 0;
                    max-width: 55%;
                }

                @media #{$sm-layout} {
                    max-width: 100%;
                    font-size: 18px;
                }
            }
        }
    }
}

/*--------------------------------------
    Banner Home 07 && 09 && 13 
------------------------------------------*/

.rbt-banner-6 {
    .inner {
        .rbt-badge {
            background: rgba(0, 0, 0, 0.45);
            color: var(--color-white);
        }

        .subtitle {
            font-size: 18px;
            letter-spacing: 1px;
            display: block;
            margin-bottom: 32px;
            color: var(--color-white);

            @media #{$md-layout} {
                font-size: 16px;
                margin-bottom: 10px;
            }

            @media #{$sm-layout} {
                font-size: 16px;
                margin-bottom: 10px;
            }

            @media #{$large-mobile} {
                font-size: 14px;
            }
        }

        .title {
            margin-bottom: 25px;
            display: inline-block;
            color: var(--color-white);
            font-size: 70px;
            font-weight: 400;

            @media #{$lg-layout} {
                font-size: 60px;
                margin-bottom: 10px;
            }

            @media #{$md-layout} {
                margin-bottom: 0;
                font-size: 50px;
            }

            @media #{$sm-layout} {
                margin-bottom: 0;
                font-size: 42px;
                word-wrap: break-word;
                width: 100%;
                overflow: hidden;
            }

            @media #{$large-mobile} {
                font-size: 42px;
            }

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                b {
                    word-wrap: break-word;
                }
            }
        }
    }

    // Home 07

    &.variation-01 {
        height: 950px;
        display: flex;
        align-items: center;

        @media #{$smlg-device} {
            height: auto;
        }

        .wrapper {
            padding-bottom: 150px;
            padding-top: 0;

            @media #{$lg-layout} {
                padding-bottom: 250px;
                padding-top: 100px;
            }

            @media #{$md-layout} {
                padding-bottom: 80px;
                padding-top: 80px;
            }

            @media #{$sm-layout} {
                padding-bottom: 80px;
                padding-top: 80px;
            }
        }

        .inner {
            .title {
                max-width: 60%;

                @media #{$md-layout} {
                    max-width: 80%;
                }

                @media #{$sm-layout} {
                    max-width: 80%;
                }

                @media #{$large-mobile} {
                    max-width: 100%;
                    font-size: 32px;
                }
            }
        }
    }

    // Home 09
    &.variation-02 {
        height: 950px;
        display: flex;
        align-items: center;

        @media #{$smlg-device} {
            height: auto;
        }

        .wrapper {
            @media #{$lg-layout} {
                padding: 100px 0;
            }

            @media #{$md-layout} {
                padding: 80px 0;
            }

            @media #{$sm-layout} {
                padding: 80px 0;
            }
        }
    }

    // Home 13

    &.variation-03 {
        height: 750px;
        display: flex;
        align-items: center;

        @media #{$smlg-device} {
            height: auto;
        }

        .wrapper {
            @media #{$lg-layout} {
                padding: 100px 0;
            }

            @media #{$md-layout} {
                padding: 100px 0;
            }

            @media #{$sm-layout} {
                padding: 100px 0;
            }
        }

        .inner {
            .title {
                @media #{$large-mobile} {
                    font-size: 32px;
                }
            }
        }
    }

}

/*-----------------------
    Banner Home 08
------------------------*/

.rbt-banner-7 {
    height: 850px;
    display: flex;
    align-items: center;

    @media #{$smlg-device} {
        height: auto;
    }

    .wrapper {
        @media #{$lg-layout} {
            padding: 100px 0;
            margin-top: -20px;
        }

        @media #{$md-layout} {
            padding: 100px 0;
            margin-top: -20px;
        }

        @media #{$sm-layout} {
            padding: 100px 0;
            margin-top: -20px;
        }
    }

    .inner {
        .title {
            margin-bottom: 34px;
            font-size: 53px;
            line-height: 1.3;
            margin-top: 15px;

            @media #{$lg-layout} {
                font-size: 36px;
            }

            @media #{$md-layout} {
                font-size: 36px;
            }

            @media #{$sm-layout} {
                font-size: 36px;
                margin-bottom: 20px;
            }

            @media #{$large-mobile} {
                font-size: 28px;
            }
        }
    }

    .thumbnail-wrapper {
        position: relative;

        .card-info {
            display: inline-block;
            position: absolute;
            bottom: 0;

            @media #{$sm-layout} {
                position: static;
                margin-top: 20px;
                margin-left: 20px;
            }

        }
    }

    .profile-share {
        @media #{$large-mobile} {
            display: block;
        }

        .more-author-text {
            @media #{$large-mobile} {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
}

/*--------------------------------------
    Banner Home 10 && 14 && 15 &&  about 
----------------------------------------*/

.rbt-banner-8 {
    .inner {
        .meta-text {
            font-weight: 600;
            font-size: 22px;
            letter-spacing: -0.5px;

            @media #{$lg-layout} {
                font-size: 16px;
            }

            @media #{$md-layout} {
                font-size: 16px;
            }

            @media #{$sm-layout} {
                font-size: 15px;
            }
        }

        .rbt-badge-2 {
            margin-left: 20px;

            @media #{$large-mobile} {
                margin-left: 0;
            }
        }

        .rbt-badge-group {
            @media #{$large-mobile} {
                display: block;
            }
        }

        .title {
            margin-bottom: 15px;
            font-size: 82px;
            line-height: 1.3;
            margin-top: 15px;
            font-weight: 900;
            letter-spacing: -0.5px;

            @media #{$lg-layout} {
                font-size: 54px;
            }

            @media #{$md-layout} {
                font-size: 52px;
            }

            @media #{$sm-layout} {
                font-size: 42px;
            }

            @media #{$large-mobile} {
                font-size: 38px;
                margin-top: 20px;
            }
        }
    }

    // Home 10
    &.variation-01 {
        height: 650px;
        display: flex;
        align-items: center;

        @media #{$smlg-device} {
            height: auto;
        }

        .wrapper {
            padding-bottom: 60px;

            @media #{$laptop-device} {
                padding-top: 80px;
                padding-bottom: 60px;
            }

            @media #{$lg-layout} {
                padding-top: 80px;
                padding-bottom: 60px;
            }

            @media #{$md-layout} {
                padding-top: 80px;
                padding-bottom: 60px;
            }

            @media #{$sm-layout} {
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }
    }

    // Home 14
    &.variation-02 {
        padding: 100px 0;

        .inner {
            .title {
                font-size: 60px;

                @media #{$md-layout} {
                    font-size: 50px;
                }

                @media #{$sm-layout} {
                    font-size: 40px;
                }

                @media #{$large-mobile} {
                    font-size: 36px;
                }
            }

            .description {
                padding: 0 26%;

                @media #{$lg-layout} {
                    padding: 0 10%;
                }

                @media #{$md-layout} {
                    padding: 0 5%;
                }

                @media #{$sm-layout} {
                    padding: 0;
                }
            }
        }
    }

    // Home 15
    &.variation-03 {
        .inner {
            .banner-title {
                font-size: 70px;
                line-height: 1;
                margin-bottom: 30px;

                @media #{$md-layout} {
                    font-size: 57px;
                }

                @media #{$sm-layout} {
                    font-size: 48px;
                    margin-bottom: 20px;
                }

                @media #{$large-mobile} {
                    font-size: 36px;
                }
            }

            .description {
                padding: 0 19%;

                @media #{$lg-layout} {
                    padding: 0 12%;
                }

                @media #{$md-layout} {
                    padding: 0 12%;
                }

                @media #{$sm-layout} {
                    padding: 0;
                }
            }

            .follow-us-text {
                color: var(--color-body);
                margin-top: 40px;
                font-weight: 400;
                letter-spacing: -0.5px;
                margin-bottom: 20px;
            }
        }

        .team {
            .content {
                .title {
                    font-size: var(--h4);
                    line-height: 1.25;
                }
            }
        }

        .rbt-dot-bottom-center {
            padding-top: 10px;
        }
    }

    &.section-bottom-overlay {
        .wrapper {
            padding-top: 100px;
            padding-bottom: 350px;

            @media #{$sm-layout} {
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }
    }

    &.with-shape {
        padding-bottom: 300px !important;
        position: relative;

        @media #{$md-layout} {
            padding-bottom: 130px !important;
        }

        @media #{$sm-layout} {
            padding-bottom: 130px !important;
        }

        .shape-image {
            position: absolute;
            bottom: 0;
            width: 60%;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0.3;
            z-index: -1;

            @media #{$sm-layout} {
                width: 89%;
            }
        }
    }
}

.banner-overlay-section {
    position: relative;
    margin-top: -278px;
    z-index: 2;

    @media #{$sm-layout} {
        margin-top: 0;
        padding-top: 80px;
    }
}


.rbt-banner-area {
    .social-default {
        &.transparent-with-border {
            li {
                a {
                    color: var(--color-white);
                    border: 2px solid var(--white-opacity);

                }
            }
        }
    }

    .follow-us-text {
        color: var(--color-white);
        font-size: 14px;
        opacity: 0.7;
        display: block;
        margin-top: 10px;
        font-weight: 300;
        letter-spacing: 0.5px;
    }
}


/* Gif Banner Area  */
.rbt-gif-banner-area {
    position: relative;
    box-shadow: var(--shadow-1);
    border-radius: var(--radius-10);
    z-index: 2;

    .thumbnail {
        img {
            outline: none;
            max-height: 600px;
            object-fit: cover;
        }
    }
}



/*-----------------------
    Banner Style 9  
------------------------*/

.rbt-banner-9 {
    justify-content: center;

    .inner {
        .title {
            font-size: 100px;
            line-height: 1.1;
            padding: 0 10%;

            @media #{$lg-layout} {
                padding: 0;
            }

            @media #{$md-layout} {
                font-size: 60px;
                padding: 0;
            }

            @media #{$sm-layout} {
                font-size: 46px;
                padding: 0;
            }
        }

        .description {
            font-size: 22px;
            line-height: 43px;
            padding: 0 24%;

            @media #{$lg-layout} {
                padding: 0;
            }

            @media #{$md-layout} {
                padding: 0;
            }

            @media #{$sm-layout} {
                font-size: 20px;
                line-height: 38px;
                padding: 0;
                margin-bottom: 10px;
            }

            @media #{$large-mobile} {
                font-size: 18px;
                line-height: 30px;
                padding: 0;
            }
        }
    }
}



/*-----------------------
    Banner Style 10 
------------------------*/

.rbt-banner-10 {
    @media #{$md-layout} {
        padding: 100px 0;
    }

    @media #{$sm-layout} {
        padding: 80px 0;
    }
}



/*-----------------------
    Banner Style 11 
------------------------*/

.rbt-banner-11 {
    .rbt-course-meta-wrapper {
        .rating {
            margin-left: 20px;
        }
    }
}



/*----------------------
 tool tip 
------------------------*/

.profile-share {
    display: flex;
    align-items: center;

    a {
        position: relative;
        z-index: 1;
        transition: var(--transition);

        &:nth-child(2) {
            margin-left: -12px;
        }

        &:nth-child(3) {
            margin-left: -12px;
        }

        img {
            max-width: 60px;
            border-radius: 50%;
            border: 3px solid var(--color-white);
            transition: var(--transition);
            box-shadow: var(--shadow-1);
        }

        &.more-author-text {
            color: var(--color-body);
            display: inline-block;
            padding-left: 7px;

            &:hover {
                transform: none;
                color: var(--color-primary);
            }
        }

        &:hover {
            z-index: 2;
            transform: translateY(-3px) scale(1.1);
        }
    }

    .more-author-text {
        margin-left: 20px;

        .total-join-students {
            margin-bottom: 4px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -0.5px;
        }

        .subtitle {
            font-size: 16px;
            letter-spacing: -0.5px;
        }
    }
}

.avatar {
    position: relative;

    &::after,
    &::before {
        --scale: 0;
        --arrow-size: 8px;
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
        transition: 150ms transform;
        transform-origin: bottom center;
    }

    &::before {
        --translate-y: calc(-100% - var(--arrow-size));
        content: attr(data-tooltip);
        color: var(--color-white);
        padding: 5px 8px;
        background: var(--color-primary);
        width: max-content;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
        margin: 1px;
    }

    &::after {
        --translate-y: calc(-1 * var(--arrow-size));
        content: '';
        border: var(--arrow-size) solid transparent;
        border-top-color: var(--color-primary);
    }

    &:hover {

        &::before,
        &::after {
            --scale: 1;
        }
    }
}


.rbt-slider-animation {
    .inner {
        >* {
            animation-name: fadeOutUp;
            animation-fill-mode: both;
            animation-duration: 1s;
        }
    }
}

.rbt-slider-animation {
    .swiper-slide-active {
        .inner {
            >* {
                animation-name: fadeInUp;
            }

            .section-title {
                animation-delay: 0.5s;
            }

            .title {
                animation-delay: 1s;
            }

            .button-group {
                animation-delay: 1.2s;
            }

            .social-share-wrapper {
                animation-delay: 1.5s;
            }
        }
    }
}


/*------------------------
    Swiper Thumb  
-------------------------*/
.rbt-swiper-thumb {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;

    .swiper-wrapper {
        justify-content: center;
    }

    .swiper-slide {
        display: inline-block;
        line-height: 100%;
        height: 80px;
        width: 80px !important;
        border-radius: 100%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        padding: 4px;
        transition: 0.4s;

        &::before {
            content: "";
            z-index: -1;
            top: 0;
            left: 0;
            position: absolute;
            background: var(--color-white);
            width: 100%;
            height: 100%;
            transition: 0.4s;
        }

        &::after {
            content: "";
            z-index: -1;
            top: 0;
            left: 0;
            position: absolute;
            background: #000;
            width: 100%;
            height: 100%;
            opacity: 0.2;
            transition: 0.4s;
        }

        &.swiper-slide-thumb-active {
            &::before {
                background: linear-gradient(90deg, var(--color-secondary), var(--color-primary));
            }

            &::after {
                opacity: 0;
            }
        }

        img {
            object-fit: cover;
            height: 100%;
            border-radius: 100%;
            width: 100%;
            transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }
}


/*--------------------------------
    Banner Home 20   
--------------------------------*/

.rbt-banner-20 {
    overflow: hidden;
    background-color: var(--color-light);
    border-radius: 0 0 20px 20px;
    padding: 155px 0 165px 0;

    @media #{$lg-layout} {
        padding: 130px 0 120px 0;
    }

    @media #{$md-layout} {
        padding: 110px 0 100px 0;
    }

    @media #{$sm-layout} {
        padding: 90px 0 80px 0;
        border-radius: 0;
    }

    @media #{$large-mobile} {
        padding: 70px 0 60px 0;
        border-radius: 0;
    }

    .circle-sd-wrapper {

        .circle-shadow-1 {
            position: absolute;
            left: -42px;
            top: 0px;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                top: -120px;
            }
        }

        .circle-shadow-2 {
            position: absolute;
            right: 0;
            top: -155px;
        }

        .circle-shadow-3 {
            position: absolute;
            left: 541px;
            bottom: 0px;
        }
    }

    .shapes-wrapper {

        .curve-line-1 {
            position: absolute;
            left: 55px;
            top: 0;
            height: 100%;

            @media #{$lg-layout, $md-layout, $sm-layout} {
                display: none;
            }

            img {
                height: 100%;
            }
        }

        .curve-line-2 {
            position: absolute;
            right: 178px;
            top: 0;
            height: 100%;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout} {
                display: none;
            }
            
            img {
                height: 100%;
            }
        }

        .curve-line-3 {
            position: absolute;
            left: 501px;
            top: 0;

            @media #{$md-layout, $sm-layout} {
                display: none;
            }
        }

        .curve-line-4 {
            position: absolute;
            right: 0;
            bottom: 0;

            @media #{$lg-layout, $md-layout, $sm-layout} {
                display: none;
            }
        }

        .shape-hand {
            position: absolute;
            left: 123px;
            bottom: 216px;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout} {
                display: none;
            }
        }

        .dot-bg {
            position: absolute;
            bottom: 0;
            left: 73px;
        }

        .dot-element {
            position: absolute;
            right: 50px;
            bottom: 50px;
        }
    }

    .col-wrap {
        @media #{$lg-layout, $md-layout, $sm-layout} {
            gap: 80px 0;
        }
    }

    .inner {
        max-width: 645px;

        @media #{$lg-layout, $md-layout, $sm-layout} {
            margin: 0 auto;
        }
        .shape-1 {
            position: absolute;
            top: -65px;
            right: 88px;
        }

        .subtitle {
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 10px;
            display: inline-block;
            color: var(--color-body);
            font-weight: 400;
        }

        .title {
            margin-bottom: 0;
            font-size: 64px;
            line-height: 1.2;
            font-weight: 600;

            @media #{$laptop-device} {
                font-size: 60px;
            }

            @media #{$lg-layout} {
                font-size: 54px;
            }
            
            @media #{$md-layout} {
                font-size: 48px;
            }
        
            @media #{$sm-layout} {
                font-size: 42px;
            }
        
            @media #{$large-mobile} {
                font-size: 32px;
            }

            span {
                img {
                    @media #{$sm-layout} {
                        max-width: 45px;
                    }

                    @media #{$large-mobile} {
                        max-width: 30px;
                    }
                }
            }
        }

        .description {
            font-size: 20px;
            max-width: 560px;

            @media #{$large-mobile} {
                font-size: 18px;
            }
        }
    }

    .language-world {
        position: relative;
        width: 500px;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: -60px;

        @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout} {
            margin: 0 auto;
        }

        @media #{$large-mobile} {
            width: 300px;
            height: 300px;
        }

        .world {
            position: relative;

            @media #{$large-mobile} {
                width: 250px;
                height: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &::after {
                content: '';
                position: absolute;
                width: 205px;
                height: 205px;
                border-radius: 50%;
                left: 50%;
                transform: translateX(-50%);
                bottom: 60px;
                background: linear-gradient(180deg, var(--color-secondary) 0%, var(--color-primary) 100%);
                filter: blur(75px);

                @media #{$large-mobile} {
                    width: 150px;
                    height: 150px;
                }
            }

            img {
                position: relative;
                z-index: 2;
            }
        }

        .flages {
            animation: roading-falgs 30s linear infinite;
            width: 500px;
            height: 500px;
            display: flex;
            justify-content: center;
            position: absolute;
            border: 2px dashed var(--color-border);
            border-radius: 50%;
            top: 0;
            z-index: 3;

            &:hover {
                animation-play-state: paused;

                .flag {
                    animation-play-state: paused;
                }
            }

            @media #{$large-mobile} {
                width: 300px;
                height: 300px;
            }

            .flag {
                position: absolute;
                z-index: 3;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--color-border);
                animation: roading-falgs 30s linear infinite reverse;
                
                &::after,
                &::before {
                    --scale: 0;
                    --arrow-size: 8px;
                    position: absolute;
                    top: -5px;
                    left: 50%;
                    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
                    transition: 150ms transform;
                    transform-origin: bottom center;
                }
            
                &::before {
                    --translate-y: calc(-100% - var(--arrow-size));
                    content: attr(data-tooltip);
                    color: var(--color-white);
                    padding: 5px 8px;
                    background: var(--color-primary);
                    width: max-content;
                    border-radius: 5px;
                    text-align: center;
                    font-size: 12px;
                    margin: 1px;
                }
            
                &::after {
                    --translate-y: calc(-1 * var(--arrow-size));
                    content: '';
                    border: var(--arrow-size) solid transparent;
                    border-top-color: var(--color-primary);
                }
            
                &:hover {
            
                    &::before,
                    &::after {
                        --scale: 1;
                    }
                }

                @media #{$large-mobile} {
                    width: 50px;
                    height: 50px;
                }

                &:nth-child(1) {
                    top: -30px;

                    @media #{$large-mobile} {
                        top: -26px;
                        left: 147px;
                    }
                }

                &:nth-child(2) {
                    top: 5px;
                    right: 85px;

                    @media #{$large-mobile} {
                        right: 25px;
                        top: 7px;
                    }
                }

                &:nth-child(3) {
                    top: 114px;
                    right: -5px;

                    @media #{$large-mobile} {
                        top: 80px;
                        right: -16px;
                    }
                }

                &:nth-child(4) {
                    bottom: 186px;
                    right: -30px;

                    @media #{$large-mobile} {
                        bottom: 84px;
                        right: -19px;
                    }
                }

                &:nth-child(5) {
                    bottom: 56px;
                    right: 30px;

                    @media #{$large-mobile} {
                        bottom: 11px;
                    }
                }

                &:nth-child(6) {
                    bottom: -17px;
                    right: 148px;

                    @media #{$large-mobile} {
                        bottom: -20px;
                        right: 110px;
                    }
                }

                &:nth-child(7) {
                    bottom: -14px;
                    left: 135px;

                    @media #{$large-mobile} {
                        bottom: -4px;
                        left: 55px;
                    }
                }

                &:nth-child(8) {
                    bottom: 66px;
                    left: 23px;

                    @media #{$large-mobile} {
                        bottom: 56px;
                        left: -9px;
                    }
                }

                &:nth-child(9) {
                    bottom: 191px;
                    left: -25px;

                    @media #{$large-mobile} {
                        bottom: 134px;
                    }
                }

                &:nth-child(10) {
                    top: 107px;
                    left: 0;

                    @media #{$large-mobile} {
                        top: 35px;
                        left: 2px;
                    }
                }

                &:nth-child(11) {
                    top: 2px;
                    left: 97px;

                    @media #{$large-mobile} {
                        top: -19px;
                        left: 69px;
                    }
                }


                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    // animation: roading-falgs 20s linear infinite;
                    // animation: roading-falgs 30s linear infinite reverse;

                    @media #{$large-mobile} {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}

@keyframes roading-falgs {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



/*--------------------------------
    Banner Home 17  
--------------------------------*/

.rbt-banner-17 {
    position: relative;
    overflow: hidden;

    .shape-wrap {

        .shape-bg {
            position: absolute;
            right: 0;
            bottom: 0;

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .shadow-bg {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }

            img {
                height: 100%;
            }
        }

        .shape-1 {
            position: absolute;
            left: 0;
            top: 0;
        }

        .shape-2 {
            position: absolute;
            left: 103px;
            bottom: 0;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .star {
            position: absolute;
            left: 154px;
            top: 148px;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .union {
            position: absolute;
            top: 91px;
            right: 194px;
            animation: edu_rotatation_round 10s linear infinite;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .dot-shape-1 {
            position: absolute;
            right: 845px;
            top: 141px;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .dot-shape-2 {
            position: absolute;
            left: 198px;
            bottom: 124px;
            animation: bounceSlide 4s ease-in-out infinite;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }
    }

    .wrapper {
        padding: 40px 0;

        @media #{$lg-layout} {
            padding: 120px 0;
        }

        @media #{$md-layout} {
            padding: 80px 0 30px 0;
        }

        @media #{$sm-layout} {
            padding: 60px 0 10px 0;
        }

        @media #{$large-mobile} {
            padding: 140px 0 0px 0;
        }
    }

    .inner {
        max-width: 705px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media #{$lg-layout, $md-layout, $sm-layout} {
            margin: 60px auto 0;
        }

        @media #{$large-mobile} {
            margin: 50px auto 0;
        }

        .line-arrow {
            position: absolute;
            bottom: 85px;
            right: 117px;

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .subtitle {
            font-size: 16px;
            line-height: 22px;
            color: var(--color-body);
            margin-bottom: 30px;

            span {
                margin-right: 10px;
                transform: translateY(-3px);
                display: inline-block;
            }

            .trustpilot-white {
                display: none;
            }

            @media #{$large-mobile} {
                margin-bottom: 15px;
            }
        }

        .title {
            margin-bottom: 20px;
            font-size: 64px;
            font-weight: 600;
            line-height: 1.2;
        
            @media #{$laptop-device} {
                font-size: 60px;
            }
        
            @media #{$lg-layout} {
                font-size: 54px;
            }
        
            @media #{$md-layout} {
                font-size: 48px;
            }
        
            @media #{$sm-layout} {
                font-size: 42px;
            }
        
            @media #{$large-mobile} {
                font-size: 32px;
            }
        }

        .description {
            font-size: 22px;
            line-height: 32px;
            max-width: 590px;

            @media #{$sm-layout} {
                font-size: 20px;
                line-height: 30px;
            }

            @media #{$large-mobile} {
                font-size: 18px;
            }
        }

        .button-group {
            display: flex;
            align-items: center;
            gap: 15px 10px;
            flex-wrap: wrap;
        }
    }

    .banner-wrap {
        position: relative;
        margin-right: -140px;

        @media #{$extra-device} {
            margin-right: -120px;
        }

        @media #{$laptop-device} {
            margin-right: -20px;
        }

        @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
            max-width: 700px;
            margin: 0 auto;
        }

        .feature {
            position: absolute;
            top: 170px;
            right: -16px;
            background: var(--color-white-off);
            border: 2px solid var(--color-extra2);
            box-shadow: var(--dark-shadow-1);
            backdrop-filter: blur(7.15px);
            border-radius: var(--radius-big);
            padding: 20px;
            display: flex;
            align-items: center;
            gap: 10px;

            @media #{$laptop-device, $sm-layout} {
                top: -8px;
            }

            @media #{$large-mobile} {
                top: -100px;
                right: 0;
            }

            @media #{$sm-layout} {
                pointer-events: none;
                transform: none !important;
            }

            &-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: var(--color-heading);
                margin-bottom: 0;
            }

            &-description {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }

            &:is(.item-2) {
                top: auto;
                bottom: 146px;
                right: 77px;

                @media #{$laptop-device, $sm-layout} {
                    bottom: 180px;
                    right: -10px;
                }

                @media #{$large-mobile} {
                    bottom: auto;
                    top: 185px;
                }
            }
        }

        .enrolled {
            position: absolute;
            bottom: 177px;
            left: 0px;
            background: var(--color-white-off);
            border: 2px solid var(--color-extra2);
            box-shadow: var(--dark-shadow-1);
            backdrop-filter: blur(7.15px);
            border-radius: var(--radius-big);
            padding: 20px;

            @media #{$laptop-device, $sm-layout} {
                bottom: 80px;
            }

            @media #{$large-mobile} {
                position: static;
                margin: 30px auto 0;
                max-width: 300px;
            }

            @media #{$sm-layout} {
                pointer-events: none;
                transform: none !important;
            }

            &-cont {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            &-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: var(--color-heading);
                margin-bottom: 0;
            }

            &-description {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }

            .profile-share {
                margin-top: 10px;

                a {
                    &:nth-child(4) {
                        margin-left: -12px;
                    }
                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}



/*--------------------------------
    Banner Home 21  
--------------------------------*/

.rbt-banner-21 {
    position: relative;
    overflow: hidden;

    .shape-wrap {

        .main-bg {
            position: absolute;
            top: -80px;
            left: 0;
            width: 100%;
            z-index: -1;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .shape-1 {
            position: absolute;
            top: -52px;
            left: 0;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .shape-2 {
            position: absolute;
            top: 202;
            right: 189px;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .shape-star {
            position: absolute;
            top: 455px;
            left: 167px;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }
    }

    .wrapper {
        padding-top: 117px;
        padding-bottom: 100px;

        @media #{$md-layout} {
            padding-top: 80px;
        }

        @media #{$sm-layout} {
            padding-top: 60px;
        }

        @media #{$large-mobile} {
            padding-top: 50px;
            padding-bottom: 80px;
        }
    }

    .subtitle {
        font-size: 18px;
        color: var(--color-body);
        line-height: 1.2;
        font-weight: 400;
        margin-bottom: 0;
    }

    .title-wrap {
        display: flex;
        align-items: center;
        gap: 20px 44px;
        flex-wrap: wrap;

        @media #{$sm-layout} {
            gap: 20px;
        }

        @media #{$laptop-device, $lg-layout, $sm-layout} {
            margin-top: 10px;
        }

        @media #{$large-mobile} {
            margin-top: 15px;
        }
    }

    .title {
        font-weight: 500;
        font-size: 190px;
        line-height: 1;
        letter-spacing: -0.64px;
        color: var(--color-darker);
        margin-bottom: 0;

        @media #{$laptop-device} {
            font-size: 160px;
        }
    
        @media #{$lg-layout} {
            font-size: 125px;
        }
    
        @media #{$md-layout} {
            font-size: 95px;
        }
    
        @media #{$sm-layout} {
            font-size: 65px;
        }
    
        @media #{$large-mobile} {
            font-size: 38px;
        }

        &:nth-child(1) {
            .icon {
                margin-right: -40px;
                transform: translateY(-10px);
                display: inline-block;
                position: relative;
                z-index: -1;

                @media #{$lg-layout} {
                    max-width: 100px;
                }
                @media #{$md-layout} {
                    max-width: 60px;
                }
                @media #{$sm-layout} {
                    max-width: 50px;
                }
                @media #{$large-mobile} {
                    max-width: 35px;
                    margin-right: -10px;
                    transform: translateY(0px);
                }
            }
        }

        &:nth-child(2) {
            .icon {
                position: relative;
                margin-right: -60px;
                display: inline-block;
                transform: translateY(-40px);

                @media #{$lg-layout} {
                    max-width: 100px;
                }
                @media #{$md-layout} {
                    max-width: 60px;
                    margin-right: -35px;
                }
                @media #{$sm-layout} {
                    max-width: 50px;
                    margin-right: -15px;
                }
                @media #{$large-mobile} {
                    max-width: 35px;
                    margin-right: -10px;
                    transform: translateY(0px);
                }
            }
        }
        
        .text-outline {
            color: transparent;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: var(--color-darker);
        }

        &:is(.school) {
            text-align: end;

            @media #{$large-mobile} {
                margin-top: 12px;
            }

            .icon {
                display: inline-block;
                transform: translateY(-15px);

                @media #{$lg-layout} {
                    max-width: 100px;
                }

                @media #{$md-layout} {
                    max-width: 60px;
                }

                @media #{$sm-layout} {
                    max-width: 50px;
                }

                @media #{$large-mobile} {
                    transform: translateY(0px);
                    max-width: 35px;
                }
            }
        }
    }

    .rbt-banner-bottom {
        margin: 0 -210px;
        position: relative;
        margin-top: -195px;
        overflow: hidden;

        @media #{$lg-layout} {
            margin: -130px 0 0 0;
        }
        @media #{$md-layout} {
            margin: -100px 0 0 0;
        }
        
        @media #{$sm-layout} {
            margin: -80px 0 0 0;
        }

        @media #{$large-mobile} {
            margin: -60px 0 0 0;
        }

        .bg-img {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .main-img {
            position: relative;

            @media #{$lg-layout, $md-layout, $large-mobile} {
                max-width: 600px;
            }

            @media #{$md-layout} {
                margin-left: -70px;
            }

            @media #{$sm-layout} {
                margin-left: -60px;
                max-width: 430px;
            }

            @media #{$large-mobile} {
                margin-left: -40px;
                max-width: 315px;
            }

            img {
                margin-bottom: -80px;

                @media #{$large-mobile} {
                    pointer-events: none;
                    transform: none !important;
                }
            }
        }
    }

    .rbt-offer {
        position: relative;
        background-size: cover;
        border: 2px solid var(--color-white);
        box-shadow: var(--shadow-1);
        backdrop-filter: blur(34.55px);
        border-radius: var(--radius-10);
        padding: 56px 140px 56px 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px 50px;
        flex-wrap: wrap;
        margin-top: -50px;

        @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
            margin-top: 2px;
        }

        @media #{$sm-layout} {
            padding: 80px 40px 56px 40px;
        }

        @media #{$large-mobile} {
            padding: 80px 30px 56px 30px;
        }

        .offer-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .offer-shape-1 {
            position: absolute;
            right: 95px;
            bottom: 80px;
            width: 70px;

            .star-1 {
                animation: edu_scale_down_up 5s linear infinite;
            }

            .star-2 {
                position: absolute;
                top: -12px;
                left: 21px;
                animation: edu_scale_up_down 5s linear infinite;
            }

            .star-3 {
                position: absolute;
                left: 21px;
                bottom: -12px;
                transform: scale(0.4);
                animation: edu_scale_down_up 10s linear infinite;
            }
        }

        .offer-shape-2 {
            position: absolute;
            right: 470px;
            bottom: 45px;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .satisfied {
            position: absolute;
            top: -40px;
            box-sizing: border-box;
            padding: 22px;
            left: 50%;
            transform: translateX(-50%) rotate(-25deg);
            background: rgb(247, 247, 247);
            border: 2px solid var(--color-white);
            box-shadow: var(--dark-shadow-5);
            backdrop-filter: blur(7.15px);
            border-radius: var(--radius-big);
            display: flex;
            align-items: center;
            gap: 10px 5.5px;
            z-index: 2;

            .cont {
                display: flex;
                flex-direction: column;
            }

            .number {
                font-weight: 600;
                font-size: 20px;
                line-height: 1.2;
                color: var(--color-darker);
                margin-bottom: 0;
            }

            .number-title {
                font-weight: 500;
                font-size: 14px;
                line-height: 1.2;
            }
        }

        .enroll {
            display: flex;
            align-items: center;
            gap: 18px;
            flex-wrap: wrap;

            .number {
                font-weight: 600;
                font-size: 20px;
                color: var(--color-darker);
                margin-bottom: 0;
            }

            .number-title {
                font-weight: 500;
                font-size: 14px;
                line-height: 1.2;
            }
        }

        .offer-content {
            max-width: 485px;
            position: relative;
            z-index: 2;
        }

        .description {
            font-weight: 400;
            font-size: 22px;
            line-height: 32px;
            margin-top: 30px;

            @media #{$large-mobile} {
                font-size: 18px;
                line-height: 28px;
                margin-top: 20px;
            }

            .parcent {
                background: var(--gradient-8);
                border-radius: var(--radius);
                color: var(--color-white);
                font-weight: 500;
            }

            .bold {
                font-weight: 500;
                color: var(--color-darker);
            }
        }
    }
}


/*--------------------------------
    Banner Home 23  
--------------------------------*/

.rbt-banner-23 {
    position: relative;
    overflow: hidden;

    .wrapper {
        padding: 212px 0 265px 0;

        @media #{$lg-layout} {
            padding: 70px 0 100px;
        }

        @media #{$md-layout} {
            padding: 60px 0;
        }

        @media #{$sm-layout} {
            padding: 50px 0 60px;
        }

        @media #{$large-mobile} {
            padding-top: 165px;
        }

        .shape-wrap {

            .main-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.2;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .bg-shape {
                position: absolute;
                left: 0;
                bottom: 0;

                @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .light-shadow {
                position: absolute;
                top: -65px;
                right: 0;

                @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                   display: none;
                }
            }

            .shape-gd-1 {
                position: absolute;
                top: -40px;
                left: 0;
            }

            .shape-yel-1 {
                position: absolute;
                left: 119px;
                bottom: 167px;

                @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .shape-arrow-1 {
                position: absolute;
                top: 265px;
                right: 240px;

                @media #{$laptop-device} {
                    right: 20px;
                }

                @media #{$md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .shape-union-1 {
                position: absolute;
                top: 210px;
                left: 730px;
                animation: roading-falgs 15s linear infinite;

                @media #{$laptop-device} {
                    left: 650px;
                }

                @media #{$md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }
        }

        .content-wrap {
            position: relative;
            z-index: 2;

            .inner {
                max-width: 705px;

                @media #{$lg-layout, $md-layout, $sm-layout} {
                    margin: 60px auto 0;
                }

                @media #{$large-mobile} {
                    margin-top: 50px;
                }

                .subtitle {
                    background: var(--white-opacity);
                    backdrop-filter: blur(5.9px);
                    border-radius: 2px;
                    display: inline-block;
                    padding: 8px 13px;
                    box-shadow: var(--shadow-1);
                    

                    span {
                        font-size: 18px;
                        line-height: 1.4;
                        font-weight: 500;
                    }
                }

                .title {
                    margin-bottom: 0;
                    font-size: 64px;
                    font-weight: 600;
                    line-height: 1.2;
                
                    @media #{$laptop-device} {
                        font-size: 60px;
                    }
    
                    @media #{$lg-layout} {
                        font-size: 54px;
                    }
    
                    @media #{$md-layout} {
                        font-size: 48px;
                    }
    
                    @media #{$sm-layout} {
                        font-size: 42px;
                    }
    
                    @media #{$large-mobile} {
                        font-size: 32px;
                    }
                }

                .description {
                    max-width: 508px;
                    font-size: 20px;
                    line-height: 32px;
                    margin-top: 20px;

                    @media #{$large-mobile} {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    .bold {
                        font-weight: 500;
                    }
                }

                .action {
                    display: flex;
                    align-items: center;
                    gap: 20px 30px;
                    flex-wrap: wrap;

                    .sucess {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .number {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 24px;
                            color: var(--color-heading);
                            margin-bottom: 0;
                        }

                        .sucess-title {
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }
                }
            }

            .main-img-wrap {
                position: absolute;
                top: -295px;
                right: -260px;
                
                @media #{$laptop-device} {
                    top: -120px;
                    right: -185px;
                    max-width: 1120px;
                }
                
                @media #{$lg-layout, $md-layout, $sm-layout} {
                    position: relative;
                    top: auto;
                    right: auto;
                }
                @media #{$large-mobile} {
                    position: relative;
                    top: auto;
                    right: auto;
                }

                .main-img {
                    position: relative;
                    z-index: 2;

                    @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                        pointer-events: none;
                        transform: none !important;
                    }
                }

                .shape {
                    position: absolute;
                    bottom: 0;
                    @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                        max-width: 830px;
                    }
                }

                .social-media {
                    position: absolute;
                    top: 450px;
                    right: 285px;
                    z-index: 3;
                    padding: 20px 20px 42px 20px;
                    min-width: 190px;

                    @media #{$lg-layout, $md-layout} {
                        top: 285px;
                        right: 105px;
                    }
                    @media #{$sm-layout} {
                        top: 150px;
                        right: 0px;
                    }
                    @media #{$large-mobile} {
                        top: -120px;
                        right: 0px;
                    }

                    .social-bg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .social-bg-dark {
                        display: none;

                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .social-default {
                        li {
                            margin: 3px;
                            a {
                                width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: transparent;
                                font-size: 15px;
                            }
                        }
                    }

                    .subtitle {
                        font-size: 12px;
                    }

                    .social-title {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 1;
                        color: var(--color-heading);
                        margin-bottom: 0;

                        img {
                            transform: translateY(-3px);
                        }
                    }
                }
            }
        }
    }
}


/*--------------------------------
    Banner Home 24  
--------------------------------*/

.rbt-banner-24 {

    .wrapper {
        position: relative;
        margin: 0 50px 0;
        padding-top: 118px;
        min-height: 820px;

        @media #{$laptop-device} {
            margin: 0;
            min-height: auto;
            padding: 200px 0 270px;
        }
        @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
            margin: 0;
            min-height: auto;
        }
        @media #{$lg-layout} {
            padding: 100px 0;
        }

        @media #{$md-layout} {
            padding: 80px 0;
        }

        @media #{$sm-layout} {
            padding: 80px 0;
        }

        @media #{$large-mobile} {
            padding: 60px 0;
        }
        
        .banner-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 820px;
            border-radius: var(--radius-10);
            overflow: hidden;

            @media #{$laptop-device} {
                height: 100%;
            }

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                height: 100%;
                border-radius: 0;
            }

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                opacity: 0.4;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content-wrapper {
            .row {
                align-items: end;

                @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    align-items: start;
                }
            }
        }

        .title-wrap {
            position: relative;
            z-index: 2;

            .rbt-new-badge {
                &.rbt-new-badge-one {
                    padding: 12px 16px 12px 16px;
                    background: var(--color-white-off);
                    margin-bottom: 15px;
                    margin-left: 20px;

                    &::before {
                        height: 30px;
                        width: calc(100% + 32px);
                        right: -16px;
                        left: -16px;
                        background: rgba(255, 255, 255, 0.3);                    
                    }
                }
            }

            .title {
                margin-bottom: 0;
                font-size: 64px;
                font-weight: 600;
                line-height: 1.2;
                color: var(--color-heading);
            
                @media #{$laptop-device} {
                    font-size: 60px;
                }

                @media #{$lg-layout} {
                    font-size: 54px;
                }

                @media #{$md-layout} {
                    font-size: 48px;
                }

                @media #{$sm-layout} {
                    font-size: 42px;
                }

                @media #{$large-mobile} {
                    font-size: 32px;
                }
            }
        }

        .inner {
            position: relative;
            z-index: 2;
            max-width: 500px;

            @media #{$lg-layout, $md-layout, $sm-layout} {
                margin-top: 30px;
            }

            @media #{$large-mobile} {
                margin-top: 15px;
            }

            .description {
                font-weight: 400;
                font-size: 22px;
                line-height: 32px;
                color: var(--color-heading);

                @media #{$sm-layout} {
                    font-size: 20px;
                    line-height: 30px;
                }
                @media #{$large-mobile} {
                    font-size: 18px;
                }

                .bold {
                    font-weight: 500;
                }
            }

            .number {
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 600;
                color: var(--color-heading);
            }

            .number-title {
                font-size: 14px;
                font-weight: 500;
                color: var(--color-body);
            }
        }
    }
}


/*--------------------------------
    Banner Home 25 
--------------------------------*/

.rbt-banner-25 {
    overflow: hidden;

    .wrapper {
        padding-top: 74px;
        position: relative;

        @media #{$lg-layout}{
            padding: 80px 0 100px;
        }

        @media #{$md-layout}{
            padding: 80px 0;
        }

        @media #{$sm-layout}{
            padding: 60px 0 80px;
        }

        @media #{$large-mobile} {
            padding: 65px 0 60px 0;
        }

        .shape-wrapper {
            .shape-1 {
                position: absolute;
                top: 0;
                right: 0;

                @media #{$sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .shape-2 {
                position: absolute;
                bottom: 0;
                left: 0;

                @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .star-1 {
                position: absolute;
                left: 768px;
                top: 238px;

                @media #{$laptop-device} {
                    left: 550px;
                }

                @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .star-2 {
                position: absolute;
                right: 273px;
                top: 223px;

                @media #{$laptop-device, $lg-layout, $md-layout} {
                    right: 70px;
                }

                @media #{$sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .shape-dot {
                position: absolute;
                bottom: 175px;
                left: 170px;

                @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .heart {
                position: absolute;
                bottom: 225px;
                right: 206px;

                @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .shape-3 {
                position: absolute;
                top: 180px;
                left: 170px;
            }
        }

        .inner {
            max-width: 510px;
            margin-top: 125px;

            @media #{$lg-layout, $md-layout, $sm-layout} {
                margin: 30px auto 0;
            }

            @media #{$large-mobile} {
                margin: 20px auto 0;
            }

            .rbt-new-badge {
                padding: 12px 24px 12px 20px;
                font-size: 18px;
                font-weight: 400;
                margin-left: 20px;

                &::before {
                    height: 30px;
                    background: #FFFFFF;
                }
            }

            .title {
                margin: 25px 0;
                font-size: 64px;
                font-weight: 600;
                line-height: 1.2;
            
                @media #{$laptop-device} {
                    font-size: 60px;
                }

                @media #{$lg-layout} {
                    font-size: 54px;
                }

                @media #{$md-layout} {
                    font-size: 48px;
                }

                @media #{$sm-layout} {
                    font-size: 42px;
                    margin-top: 20px;
                }

                @media #{$large-mobile} {
                    font-size: 32px;
                    margin-bottom: 20px;
                }

                img {
                    max-width: 100px;
                    max-height: 60px;
                    border-radius: 48px;
                    transform: translateY(-7px);
                
                    @media #{$sm-layout} {
                        max-width: 65px;
                        transform: translateY(-5px);
                    }

                    @media #{$large-mobile} {
                        max-width: 54px;
                        transform: translateY(-3px);
                    }
                }
            }

            .description {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 35px;

                @media #{$large-mobile} {
                    margin-bottom: 25px;
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }

    &-thumbnails {
        position: relative;
        margin-right: -100px;
        display: flex;
        justify-content: center;
        min-height: 750px;

        @media #{$sm-layout} {
            min-height: 680px;
        }

        @media #{$lg-layout, $md-layout, $sm-layout} {
            margin-right: -50px;
        }

        @media #{$large-mobile} {
            flex-direction: column;
            justify-content: end;
            margin-right: 0px;
            min-height: 635px;
        }
        
        .shadow-shape {
            position: absolute;
            bottom: 0;
            right: 78px;

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .main-img {
            position: relative;
            z-index: 2;

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                pointer-events: none;
                transform: none !important;
            }
        }
    }

    &-satisfied {
        position: absolute;
        z-index: 2;
        top: 225px;
        left: 50px;
        background: var(--color-white-off);
        border: 2px solid var(--color-extra2);
        box-shadow: var(--dark-shadow-1);
        backdrop-filter: blur(7.15px);
        border-radius: var(--radius-big);
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 10px;

        @media #{$large-mobile} {
            left: 0;
            top: -30px;
        }

        .cont {
            display: flex;
            flex-direction: column;
            
            .satis-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: var(--color-heading);
                margin-bottom: 0;
            }

            .satis-subtitle {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &-review {
        position: absolute;
        z-index: 2;
        top: 265px;
        right: 70px;
        background: var(--color-white-off);
        border: 2px solid var(--color-white);
        box-shadow: var(--dark-shadow-1);
        backdrop-filter: blur(7.15px);
        border-radius: var(--radius-big);
        padding: 20px;

        @media #{$large-mobile} {
            top: 240px;
            right: 0px;
        }

        .raging {
            display: flex;
            align-items: center;

            i {
                color: var(--color-badge-2);
                font-size: 15px;
            }
        }

        .heart {
            width: 27px;
            height: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: var(--color-gray-light);

            i {
                color: var(--color-danger);
                font-size: 15px;
            }
        }

        .review-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            color: var(--color-heading);
            margin: 10px 0;
        }

        .parcent {
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
            color: #FFFFFF;
            background-color: var(--color-success);
            border-radius: var(--radius-big);
            padding: 3px 10px;
        }

        .review-subtitle {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-team {
        position: absolute;
        z-index: 2;
        top: 425px;
        left: 10px;
        background: var(--color-white-off);
        border: 2px solid var(--color-white-off);
        box-shadow: var(--dark-shadow-1);
        backdrop-filter: blur(13.75px);
        border-radius: var(--radius-big);
        padding: 20px;
        text-align: center;
        max-width: 185px;

        @media #{$large-mobile} {
            max-width: 285px;
            position: static;
            margin: 25px auto 0;
        }
        
        .day {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            color: var(--color-primary);
            margin-bottom: 0;
        }

        .team-title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
        }
        
        .profile-share {
            margin: 10px 0 6px 0;
            justify-content: center;

            a {
                &:nth-child(4) {
                    margin-left: -12px;
                }

                img {
                    width: 34px;
                    height: 34px;
                }

            }

            .number {
                background: var(--color-primary-light);
                border: 1.5px solid var(--color-white);
                border-radius: 50%;
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
                color: var(--color-darke);
                width: 34px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                margin-left: -12px;
                position: relative;
                z-index: 1;
            }

        }
    }
    .team-join {
        font-size: 14px;
        line-height: 20px;
        display: block;
        text-transform: capitalize;
    }
}


/*--------------------------------
    Banner Home 26 
--------------------------------*/

.rbt-banner-26 {
    overflow: hidden;

    .wrapper {
        padding: 70px 0 180px 0;
        position: relative;

        @media #{$lg-layout, $md-layout} {
            padding: 60px 100px;
        }

        @media #{$sm-layout} {
            padding: 50px 80px;
        }
        
        @media #{$large-mobile} {
            padding: 130px 0 60px 0;
        }

        .shape-wrapper {
            .shape-1 {
                position: absolute;
                top: -52px;
                left: 0;

                @media #{$laptop-device} {
                    max-width: 130px;
                }

                @media #{$lg-layout,
                    $md-layout,
                    $sm-layout,
                    $large-mobile} {
                    display: none;
                }
            }

            .shape-2 {
                position: absolute;
                top: -70px;
                right: -25px;

                @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    max-width: 120px;
                }

                @media #{$large-mobile} {
                    max-width: 90px;
                }
            }

            .star-1 {
                position: absolute;
                left: 90px;
                top: 381px;

                @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .moon {
                position: absolute;
                left: 730px;
                top: 120px;

                @media #{$md-layout,
                    $sm-layout,
                    $large-mobile
                }
                    {
                    display: none;
                }
            }

            .quran {
                position: absolute;
                left: 790px;
                bottom: 185px;
                
                @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .banner {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;

                img {
                    width: 100%;

                    @media #{$large-mobile} {
                        pointer-events: none;
                        transform: none !important;
                    }
                }
            }
        }

        .inner {
            max-width: 650px;
            margin-top: 50px;
            position: relative;
            z-index: 2;

            @media #{$lg-layout, $md-layout, $sm-layout} {
                margin: 80px auto 0;
            }

            @media #{$large-mobile} {
                margin: 50px auto 0;
            }

            .subtitle {
                font-weight: 500;
                font-size: 16px;
                line-height: 32px;
                color: var(--color-primary);
                margin-bottom: 0;
                padding: 1px 16px;
                background: var(--color-extra2);
                border-radius: 42px;
                display: inline-block;
                border: 1px solid var(--primary-opacity);

                img {
                    transform: translateY(-1px);
                }
            }

            .title {
                margin: 25px 0;
                font-size: 64px;
                font-weight: 600;
                line-height: 1.2;

                @media #{$laptop-device} {
                    font-size: 60px;
                }

                @media #{$lg-layout} {
                    font-size: 54px;
                }

                @media #{$md-layout} {
                    font-size: 48px;
                }

                @media #{$sm-layout} {
                    font-size: 42px;
                    margin-top: 15px;
                }

                @media #{$large-mobile} {
                    font-size: 32px;
                    margin-bottom: 20px;
                }

                img {
                    max-width: 100px;
                    max-height: 60px;
                    border-radius: 48px;
                    transform: translateY(-7px);

                    @media #{$sm-layout} {
                        max-width: 65px;
                        transform: translateY(-5px);
                    }

                    @media #{$large-mobile} {
                        max-width: 52px;
                        transform: translateY(-3px);
                    }
                }
            }

            .description {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 35px;
                max-width: 508px;

                @media #{$large-mobile} {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 25px;
                }

                .bold {
                    font-weight: 500;
                }
            }
        }
    }

    &-thumbnails {
        position: relative;
        display: flex;
        justify-content: center;

        @media #{$lg-layout, $md-layout, $sm-layout} {
            margin: 0 auto;
            max-width: 600px;
        }

        @media #{$large-mobile} {
            flex-direction: column;
        }

        .shape-dot {
            position: absolute;
            bottom: 0;
            right: 0;

            @media #{$large-mobile} {
                display: none;
            }
        }

        .main-img {
            position: relative;
            z-index: 2;
        }
    }

    &-satisfied {
        position: absolute;
        z-index: 2;
        top: 175px;
        left: 0px;
        background: var(--color-white-off);
        border: 2px solid var(--color-extra2);
        box-shadow: var(--dark-shadow-1);
        backdrop-filter: blur(7.15px);
        border-radius: var(--radius-big);
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 10px;

        @media #{$large-mobile} {
            left: 0;
            top: -90px;
        }

        .cont {
            display: flex;
            flex-direction: column;

            .satis-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: var(--color-heading);
                margin-bottom: 0;
            }

            .satis-subtitle {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &-album {
        position: absolute;
        z-index: 2;
        top: 185px;
        right: -75px;
        background: var(--color-white-off);
        border: 2px solid var(--color-extra2);
        box-shadow: var(--dark-shadow-1);
        backdrop-filter: blur(7.15px);
        border-radius: var(--radius-big);
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 10px;

        @media #{$laptop-device, $sm-layout} {
            right: 0;
        }

        .cont {
            display: flex;
            flex-direction: column;

            .satis-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: var(--color-heading);
                margin-bottom: 0;
            }

            .satis-subtitle {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }


    &-team {
        position: absolute;
        z-index: 2;
        bottom: -30px;
        left: 10px;
        background: var(--color-white-off);
        border: 2px solid var(--color-white-off);
        box-shadow: var(--dark-shadow-1);
        backdrop-filter: blur(13.75px);
        border-radius: var(--radius-big);
        padding: 20px;
        max-width: 200px;

        @media #{$large-mobile} {
            max-width: 285px;
            position: static;
            margin: 20px auto 0;
        }

        .team-cont {
            display: flex;
            align-items: center;
            gap: 10px;
            .cont {
                display: flex;
                flex-direction: column;
    
                .satis-title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: var(--color-heading);
                    margin-bottom: 0;
                }
    
                .satis-subtitle {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .profile-share {
            margin: 10px 0 0 0;

            a {
                &:nth-child(4) {
                    margin-left: -12px;
                }

                img {
                    width: 40px;
                    height: 40px;
                }

            }

        }
    }
}

/*--------------------------------
    Banner Home 22 
--------------------------------*/

.rbt-banner-22 {
    .wrapper {
        padding: 130px 0 140px 0;
        overflow: hidden;
        position: relative;

        @media #{$md-layout} {
            padding: 100px 0 140px 0;
        }

        @media #{$large-mobile} {
            padding: 100px 0 100px 0;
        }
    }

    .inner {
        position: relative;

        .content {
            max-width: 980px;
            margin: 0 auto;

            .title {
                font-weight: 600;
                font-size: 90px;
                line-height: 1;
                text-align: center;
                letter-spacing: -0.03em;
                color: var(--color-heading);
                margin-bottom: 0;

                @media #{$laptop-device} {
                    font-size: 60px;
                }
            
                @media #{$lg-layout} {
                    font-size: 54px;
                }
            
                @media #{$md-layout} {
                    font-size: 48px;
                }
            
                @media #{$sm-layout} {
                    font-size: 42px;
                }
            
                @media #{$large-mobile} {
                    font-size: 32px;
                }

                .theme-gradient {
                    position: relative;
                    overflow: hidden;

                    img {
                        position: absolute;
                        max-width: 56px;

                        @media #{$laptop-device} {
                            max-width: 25px;
                        }
                    
                        @media #{$lg-layout} {
                            max-width: 25px;
                        }
                    
                        @media #{$md-layout} {
                            max-width: 20px;
                        }
                    
                        @media #{$sm-layout} {
                            max-width: 18px;
                        }
                    
                        @media #{$large-mobile} {
                            max-width: 15px;
                        }
                    }
                }
            }

            .description {
                text-align: center;
                margin: 15px 0 25px 0;
            }

            .newsletter-form-1 {
                input {
                    height: 60px;
                }

                .rbt-btn {
                    right: 5px;
                }
            }

            .students {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 10px;
                margin-top: 20px;

                .profile-share {
                    a {
                        img {
                            max-width: 38px;
                            border: 2px solid var(--color-white);
                        }
                    }
                }

                .number {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--color-white);
                    margin-bottom: 0;
                }

                .number-title {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--color-white);
                }
            }
        }

        .shape-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;

            .shape-1 {
                position: absolute;
                top: -70px;
            }

            .shape-2 {
                position: absolute;
                bottom: 138px;
                right: 0;

                @media #{$sm-layout, $large-mobile} {
                    display: none;
                }
            }

            .shape-3 {
                position: absolute;
                bottom: 20px;
                left: 0;

                @media #{$small-mobile} {
                    display: none;
                }
            }
        }
    }

    .banner {
        position: relative;

        .shape-shadow {
            position: absolute;
            top: -320px;
            z-index: -1;

            @media #{$sm-layout} {
                top: -190px;
            }

            @media #{$large-mobile} {
                top: -100px;
            }
        }

        .browser {
            position: relative;
            text-align: center;
            margin: 0 -8px;

            .browser-img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 862px;

                @media #{$laptop-device} {
                    height: 762px;
                }

                @media #{$lg-layout} {
                    height: 630px;
                }

                @media #{$md-layout} {
                    height: 472px;
                }

                @media #{$sm-layout} {
                    height: 348px;
                }

                @media #{$large-mobile} {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
            video {
                position: relative;
                margin-top: 108px;
                width: calc(100% - 48px);
                max-width: 1231px;
                border-radius: var(--radius-10);

                @media only screen and (min-width: 1200px) and (max-width: 1399px) {
                    max-width: 1063px;
                }

                @media #{$lg-layout} {
                    max-width: 896px;
                    margin-top: 81px;
                }

                @media #{$md-layout} {
                    margin-top: 60px;
                }

                @media #{$sm-layout} {
                    margin-top: 46px;
                    width: calc(100% - 36px);
                }

                @media #{$large-mobile} {
                    margin-top: 40px;
                    width: 100%;
                }
            }
        }
    }
}


/*--------------------------------
    Banner Home 18  
--------------------------------*/

.rbt-banner-18 {
    position: relative;

    @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
        overflow: hidden;
    }

    .shape-wrap {

        .shadow-1 {
            position: absolute;
            left: 207px;
            top: -37px;
            width: 92px;
            height: 92px;
            border-radius: 50%;
            background: var(--gradient-dark);
            filter: blur(50px);
            transform: rotate(-45deg);
            opacity: 0.8;
        }

        .shadow-2 {
            position: absolute;
            right: 460px;
            top: -138px;
            width: 310px;
            height: 310px;
            border-radius: 50%;
            background: var(--gradient-dark);
            filter: blur(400px);
            transform: rotate(-45deg);
            opacity: 0.5;
        }

        .shadow-3 {
            position: absolute;
            left: -155px;
            top: 688px;
            width: 396px;
            height: 396px;
            border-radius: 50%;
            background: var(--gradient-dark);
            filter: blur(400px);
            transform: rotate(-45deg);
            opacity: 0.5;
        }

        .shadow-4 {
            position: absolute;
            right: 193px;
            top: 822px;
            width: 254px;
            height: 254px;
            background: linear-gradient(180deg, var(--color-pink) 0%, var(--color-violet) 100%);
            filter: blur(400px);
            transform: rotate(-45deg);
            opacity: 0.5;
        }

        .glass {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0.3;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .union {
            position: absolute;
            top: 180px;
            right: 325px;
            animation: edu_rotatation_round 10s linear infinite;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .dot-shape-1 {
            position: absolute;
            right: 922px;
            top: 141px;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }
    }

    .wrapper {
        padding: 66px 0 100px 0;

        @media #{$lg-layout} {
            padding: 100px 0;
        }

        @media #{$md-layout} {
            padding: 80px 0 100px 0;
        }

        @media #{$sm-layout} {
            padding: 80px 0 100px 0;
        }

        @media #{$large-mobile} {
            padding: 60px 0 100px 0;
        }
    }

    .inner {
        max-width: 705px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media #{$lg-layout, $md-layout, $sm-layout} {
            margin: 0 auto;
        }

        .line-arrow {
            position: absolute;
            bottom: 85px;
            right: 117px;

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .subtitle {
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 20px;
            font-weight: 400;

            &::first-letter {
                font-weight: 600;
            }

            @media #{$large-mobile} {
                margin-bottom: 10px;
            }
        }

        .sec-title {
            margin-bottom: 20px;
            font-size: 64px;
            font-weight: 600;
            line-height: 1.2;
        
            @media #{$laptop-device} {
                font-size: 60px;
            }
        
            @media #{$lg-layout} {
                font-size: 54px;
            }
        
            @media #{$md-layout} {
                font-size: 48px;
            }
        
            @media #{$sm-layout} {
                font-size: 42px;
            }
        
            @media #{$large-mobile} {
                font-size: 32px;
            }
        }

        .description {
            font-size: 22px;
            line-height: 32px;
            max-width: 590px;
            margin-bottom: 40px;

            @media #{$sm-layout} {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 30px;
            }

            @media #{$large-mobile} {
                font-size: 18px;
            }
        }

        .button-group {
            display: flex;
            align-items: center;
            gap: 15px 10px;
            flex-wrap: wrap;
        }
    }

    .banner-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{$lg-layout, $md-layout, $sm-layout} {
            max-width: 550px;
            margin: 60px auto 0;
        }

        @media #{$large-mobile} {
            margin: 140px auto 40px;
        }

        .banner {
            @media #{$large-mobile} {
                pointer-events: none;
                transform: none !important;
            }
        }


        .feature {
            position: absolute;
            top: 285px;
            right: -110px;
            background: var(--color-white-off);
            border: 2px solid var(--color-extra2);
            box-shadow: var(--dark-shadow-1);
            backdrop-filter: blur(7.15px);
            border-radius: var(--radius-big);
            padding: 20px;
            display: flex;
            align-items: center;
            gap: 10px;

            @media #{$laptop-device, $sm-layout} {
                right: 0;
            }

            @media #{$large-mobile} {
                top: -100px;
                right: 0;
            }

            &-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: var(--color-heading);
                margin-bottom: 0;
            }

            &-description {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }

            &:is(.item-2) {
                top: 227px;
                right: auto;
                left: -21px;

                @media #{$large-mobile} {
                    bottom: auto;
                    top: 185px;
                    left: 0;
                }
            }
        }

        .enrolled {
            position: absolute;
            bottom: 150px;
            left: -95px;
            background: var(--color-white-off);
            border: 2px solid var(--color-extra2);
            box-shadow: var(--dark-shadow-1);
            backdrop-filter: blur(7.15px);
            border-radius: var(--radius-big);
            padding: 20px;

            @media #{$laptop-device, $sm-layout} {
                bottom: 80px;
            }

            @media #{$sm-layout} {
                left: 0;
            }

            @media #{$large-mobile} {
                position: static;
                margin: 30px auto 0;
                max-width: 300px;
            }

            &-cont {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            &-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: var(--color-heading);
                margin-bottom: 0;
            }

            &-description {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }

            .profile-share {
                margin-top: 10px;

                a {
                    &:nth-child(4) {
                        margin-left: -12px;
                    }
                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

    .brand-box-wrap {
        position: relative;

        .uion {
            position: absolute;
            top: -51px;
            left: -97px;
            animation: edu_rotatation_round 30s linear infinite reverse;

            @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .leaf {
            position: absolute;
            top: -10px;
            right: -34px;
        }

        .brand-box {
            box-shadow: 0px 4px 65.6px rgba(25, 35, 53, 0.05);
            border-radius: 12px;
            padding: 50px 35px 60px 35px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 40px;
                left: 60px;
                width: calc(100% - 120px);
                height: 100%;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0px 4px 65.6px rgba(25, 35, 53, 0.05);
                border-radius: 12px;
                z-index: -1;
            }

            &::after {
                content: "";
                position: absolute;
                top: 20px;
                left: 30px;
                width: calc(100% - 60px);
                height: 100%;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0px 4px 65.6px rgba(25, 35, 53, 0.05);
                border-radius: 12px;
                z-index: -1;
            }

            .brand-list {
                max-width: 1110px;
                margin: 0 auto;

                li {
                    margin: 0;
                }
            }
        }
    }
}


/*--------------------------------
    Banner Home 19  
--------------------------------*/

.rbt-banner-19 {
    .wrapper {
        margin: 0 50px;

        @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
            margin: 0;
        }
    }

    .rbt-banner-activation-2 {
        .rbt-banner-item {
            padding: 185px 0 245px 0;
            border-radius: var(--radius-10);
            position: relative;

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                border-radius: 0;
            }

            @media #{$large-mobile} {
                padding: 50px 0 140px 0;
            }

            .shape-1 {
                position: absolute;
                left: 100px;
                bottom: 40px;

                @media #{$large-mobile} {
                    display: none;
                }
            }

            .inner {
                max-width: 705px;
                margin-left: auto;

                .subtitle {
                    font-weight: var(--f-regular);
                    font-size: var(--font-size-b3);
                    line-height: 22px;
                    color: var(--color-white);

                    span {
                        margin-right: 10px;
                        transform: translateY(-3px);
                        display: inline-block;
                    }
                }

                .title {
                    margin-bottom: 20px;
                    font-size: 64px;
                    font-weight: 600;
                    line-height: 1.2;
                    color: var(--color-white);
                    animation-delay: 0s;
                
                    @media #{$laptop-device} {
                        font-size: 60px;
                    }
                
                    @media #{$lg-layout} {
                        font-size: 54px;
                    }
                
                    @media #{$md-layout} {
                        font-size: 48px;
                    }
                
                    @media #{$sm-layout} {
                        font-size: 42px;
                    }
                
                    @media #{$large-mobile} {
                        font-size: 32px;
                    }
                }

                .description {
                    font-weight: var(--f-regular);
                    font-size: 22px;
                    line-height: 32px;
                    color: var(--color-white);
                    max-width: 595px;
                    animation-delay: 0.4s;

                    @media #{$sm-layout} {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    @media #{$large-mobile} {
                        font-size: 18px;
                    }
                }

                .bottom-content {
                    animation-delay: 0.8s;
                }

                .profile-share {
                    a {
                        img {
                            max-width: 48px;
                        }
                    }
                }

                .number {
                    font-weight: var(--f-semi-bold);
                    font-size: 20px;
                    line-height: 25px;
                    color: var(--color-white);
                }

                .number-title {
                    font-weight: var(--f-medium);
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--color-white);
                }
            }
        }
    }
}

/*--------------------------------
    Event Section shapes  
--------------------------------*/

.event-section-01 {
    position: relative;

    .shapes {
        .shape-1 {
            position: absolute;
            top: 0;
            right: 0;

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .shape-2 {
            position: absolute;
            bottom: 0;
            left: 0;

            @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }

        .shape-3 {
            position: absolute;
            right: 124px;
            bottom: 147px;

            @media #{$md-layout, $sm-layout, $large-mobile} {
                display: none;
            }
        }
    }
}


/*--------------------------------
    Course Section shapes  
--------------------------------*/

.course-section-01 {
    position: relative;
    overflow: hidden;

    .shape-1 {
        position: absolute;
        right: 133px;
        top: 0;

        @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile}{
            display: none;
        }
    }

    .shape-2 {
        position: absolute;
        left: 34px;
        bottom: 540px;

        @media #{$laptop-device, $lg-layout, $md-layout, $sm-layout, $large-mobile}{
            display: none;
        }
    }

    .circle-shadow {
        position: absolute;
        top: -350px;
        left: -350px;

        @media #{$lg-layout, $md-layout, $sm-layout, $large-mobile}{
            display: none;
        }
    }
}